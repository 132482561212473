import Card from "components/card";
import { useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
} from "react-share";
import { EmailIcon } from "react-share";
import "react-quill/dist/quill.snow.css";
import { BsFillFileWordFill, BsFillFilePdfFill } from "react-icons/bs";

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function AIOutput({
  Emailbody,
  onEditorStateChange,
  editorState,
  isLoading,
  errorMessage,
  isBusinessPlan = false,
  extra,
  blank = false,
}) {
  const editorRef = useRef(null);

  function exportToWord() {
    const content = editorRef.current.getContent();
    // console.log(content);
    // const html = editorRef.current.editor.getContent({format: 'html'});
    // console.log(html);
    // const text = editorRef.current.editor.getContent({format: 'text'});
    // console.log(text);

    let form = document.createElement("form");
    form.setAttribute("method", "post");
    // http://localhost/export%20to%20word%20and%20pdf/html-to-word.php
    form.setAttribute("action", "https://export.copymax.io/html-to-word.php");

    // let fileName = document.createElement("input");
    // fileName.setAttribute("type", "hidden");
    // fileName.setAttribute("name", "fileName");
    // fileName.setAttribute("value", "get from props");

    // form.appendChild(fileName);

    let hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", "content");
    hiddenField.setAttribute("value", content);

    form.appendChild(hiddenField);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  function exportToPDF() {
    const content = editorRef.current.getContent();
    // console.log(content);
    // const html = editorRef.current.editor.getContent({format: 'html'});
    // console.log(html);
    // const text = editorRef.current.editor.getContent({format: 'text'});
    // console.log(text);

    let form = document.createElement("form");
    form.setAttribute("method", "post");
    // http://localhost/export%20to%20word%20and%20pdf/html-to-pdf.php
    form.setAttribute("action", "https://export.copymax.io/html-to-pdf.php");

    // let fileName = document.createElement("input");
    // fileName.setAttribute("type", "hidden");
    // fileName.setAttribute("name", "fileName");
    // fileName.setAttribute("value", "get from props");

    // form.appendChild(fileName);

    let hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", "content");
    hiddenField.setAttribute("value", content);

    form.appendChild(hiddenField);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  return (
    <Card extra={"px-4 py-5 space-y-3 overflow-x-auto"}>
      <h1 className="text-2xl font-extrabold text-navy-700 dark:text-white">
        {blank ? "" : "AI Output"}
      </h1>
      {Emailbody && (
        <>
          {isBusinessPlan && (
            <div className="flex w-full items-center space-x-5">
              <p className="font-medium text-navy-900 dark:text-[#fff]">
                Share via :
              </p>
              <div>
                <EmailShareButton subject="" body={Emailbody}>
                  <EmailIcon size={32} round={true} />
                </EmailShareButton>
              </div>
              <div>
                <LinkedinShareButton
                  title=""
                  summary={Emailbody}
                  source="copyax ai"
                  url="copymaxai.com"
                >
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
              </div>
              <div>
                <a href={`http://twitter.com/share?text=${Emailbody}`}>
                  <TwitterIcon size={32} round={true} />
                </a>
              </div>
            </div>
          )}
          {blank ? (
            ""
          ) : (
            <p className="text-lg font-medium text-gray-600">Enjoy!!!</p>
          )}
          <div className="flex items-center justify-end space-x-3">
            <p className="text-lg">Save as:</p>

            <button
              className="inline-flex rounded-md bg-red-700 px-2 py-2 text-sm font-medium text-white"
              onClick={exportToPDF}
            >
              <BsFillFilePdfFill className="mr-2 h-5 w-5" />
              PDF
            </button>
          </div>
        </>
      )}
      <div
        className={`relative h-[600px] w-auto rounded-lg border-[2px] border-gray-700 text-navy-700 ${extra}`}
      >
        {isLoading && !errorMessage && (
          <div className="absolute z-50 flex h-full w-full items-center justify-center bg-[#000] bg-opacity-40">
            <ScaleLoader
              loading={isLoading}
              size={70}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="green"
            />
          </div>
        )}

        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
          onInit={(evt, editor) => (editorRef.current = editor)}
          init={{
            height: "100%",
            menubar: true,
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "preview",
              "help",
              "wordcount",
              "image",
            ],
            toolbar:
              "undo redo | blocks | image " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            /* enable title field in the Image dialog*/
            image_title: false,
            /* enable automatic uploads of images represented by blob or data URIs*/
            automatic_uploads: false,
            /*
              URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
              images_upload_url: 'postAcceptor.php',
              here we add custom filepicker only to Image dialog
            */
            file_picker_types: "image",
            /* and here's our custom image picker*/
            file_picker_callback: (cb, value, meta) => {
              const input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");

              input.addEventListener("change", (e) => {
                const file = e.target.files[0];

                const reader = new FileReader();
                reader.addEventListener("load", () => {
                  /*
                    Note: Now we need to register the blob in TinyMCEs image blob
                    registry. In the next release this part hopefully won't be
                    necessary, as we are looking to handle it internally.
                  */
                  const id = "blobid" + new Date().getTime();
                  const blobCache = editorRef.current.editorUpload.blobCache;
                  const base64 = reader.result.split(",")[1];
                  const blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);

                  /* call the callback and populate the Title field with the file name */
                  cb(blobInfo.blobUri(), { title: file.name });
                });
                reader.readAsDataURL(file);
              });

              input.click();
            },
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
          value={editorState}
          onEditorChange={(newValue, editor) => {
            onEditorStateChange(newValue);
            // setText(editor.getContent({format: 'text'})); // if you need as text
          }}
        />
      </div>
    </Card>
  );
}
