import ScaleLoader from "react-spinners/ScaleLoader";



export default function ( { isLoading } ) {
    return (
        <div className="fixed h-full top-0 left-0 flex w-full items-center justify-center overflow-hidden bg-[#000] bg-opacity-50">
          <ScaleLoader
            loading={isLoading}
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
            color="green"
          />
        </div>
    )
}