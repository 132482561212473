import React, { useEffect, useState } from "react";
import avatar from "assets/img/avatars/univatar.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import CardMenu from "components/card/CardMenu";
import { getCookie } from "Utils";
import { BiInfoCircle } from "react-icons/bi";
import { buildApiEndpoint } from "Utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Banner = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const storedUser = getCookie("user"); // localStorage.getItem("user");
  const user = JSON.parse(storedUser);
  const createdAt = user.createdAt;
  const formattedDate = new Date(createdAt).toLocaleDateString(); // Format as "MM/DD/YYYY"
  const formattedTime = new Date(createdAt).toLocaleTimeString(); // Format as "HH:MM:SS"

  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  const handleFundsWithdrawal = async (e) => {
    setIsLoading(true);
    try {
      if (!email) {
        toast.error("Please fill all fields", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      } else if (
        !email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        toast.error("Please input a valid email address", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        const response = await fetch(buildApiEndpoint("/withdraw"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });
        if (response.ok) {
          setIsLoading(false);
          toast.success("Your funds is being processed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          return;
        } else {
          const errorResponse = await response.json();
          toast.error("Error Processing Withdrawal", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error! Please try again later", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setIsLoading(false);
    }
  };

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-gray-700 dark:!border-navy-700">
          <img className="h-full w-full rounded-full" src={avatar} alt="" />
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {user.name}
        </h4>
        <p className="text-base font-normal text-gray-600">{user.email}</p>
        <p className="text-base font-normal text-gray-600">
          Since: {formattedDateTime}
        </p>
        <h3 className="mt-4 text-xl font-bold text-navy-700 dark:text-white">
          Your referral code:{" "}
          <span className=" text-green-600">{user.referralCode}</span>
        </h3>
        <h3 className=" text-xl font-bold text-navy-700 dark:text-white">
          Your referral Earnings: ${user.referralEarnings}{" "}
        </h3>
        {user.referralEarnings >= 50 ? (
          <button
            className="rounded-lg bg-green-600 px-4 py-2 text-base font-medium text-white active:scale-90"
            onClick={() => document.getElementById("my_modal_5").showModal()}
          >
            Withdraw
          </button>
        ) : (
          ""
        )}

        <dialog id="my_modal_5" className="modal modal-bottom sm:modal-middle">
          <div className="modal-box">
            <h3 className="mb-2 border-b-2 border-gray-300 text-lg font-bold">
              Withdraw Funds
            </h3>
            <p className="py-4">
              Please input your PayPal email address to withdraw your funds
            </p>
            <input
              type="text"
              placeholder="example@mail.com"
              className="input input-bordered -mt-1 w-full max-w-sm"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <div className="modal-action">
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn">Close</button>
              </form>
              <button
                className="btn"
                onClick={() => {
                  if (!isLoading) {
                    handleFundsWithdrawal();
                  }
                }}
              >
                {isLoading ? "Processing..." : "Proceed"}
              </button>
            </div>
          </div>
        </dialog>

        <div className="mb-2 mt-3 rounded-lg border-2 border-navy-900 bg-green-100 px-2 py-3">
          <h3 className=" text-left text-base font-bold text-navy-700 dark:text-white">
            <BiInfoCircle className="inline" /> Get $1 everytime someone you
            refer purchases a plan on CopyMax. Withdrawable once referral
            earnings reaches $50
          </h3>
        </div>
      </div>
      <div className="ml-auto mt-[auto]">
        <CardMenu />
      </div>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </Card>
  );
};

export default Banner;
