import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [bgdark, setBgdark] = useState(true);
  const [opend, setOpend] = useState(false);

  const login = (userData) => {
    setUser(userData);
  };
  const logout = () => {
    setUser(null);
  };
  const handleModal = () => {
    setShowModal(!showModal);
  };
  const handleModal3 = () => {
    setShowModal3(!showModal3);
  };
  const handleModal2 = () => {
    setShowModal2(!showModal2);
  };
  const handleBg = (data) => {
    setBgdark(data);
    console.log(bgdark);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        handleModal,
        showModal,
        handleModal2,
        showModal2,
        handleBg,
        bgdark,
        opend,
        setOpend,
        showModal3,
        handleModal3
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
