import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar2 from "components/sidebar/index2";
import Footer from "components/footer/Footer";
import adminroutes from "adminroutes.js";
import { getCookie } from "Utils";
import ProjectDetails from "views/projectdetails";

const openable = () => !(window.innerWidth < 1200);

export default function (props) {
  const { pathGroup, index = false, ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = useState(openable);
  const [currentRoute, setCurrentRoute] = useState("Main Dashboard");

  // todo: pass down to children componenets using useContext()
  const storedUser = getCookie("user");
  if (storedUser == null || !storedUser) window.location = "/auth/sign-in";

  useEffect(() => {
    window.addEventListener("resize", () => setOpen(openable));
  }, []);

  useEffect(() => {
    getActiveRoute(adminroutes);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      } else if (
        window.location.href.indexOf("/pricing") !== -1 &&
        routes[i].layout == "/pricing"
      )
        setCurrentRoute(routes[i].name);
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === `/${pathGroup}`) {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar2 open={open} onClose={() => setOpen(openable)} />

      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              toggleOpenSidenav={() => setOpen(open ? false : true)}
              logoText={"Copymax"}
              brandText={currentRoute}
              secondary={getActiveNavbar(adminroutes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto mt-10 h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(adminroutes)}

                {index && (
                  <Route
                    path="/"
                    element={<Navigate to={`/${pathGroup}/${index}`} replace />}
                  />
                )}
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
