import Banner from "./components/Banner";
import Storage from "./components/Storage";
import Switch from "../../../components/switch";
import { useEffect, useState } from "react";
import { useAuth } from "authContext";
import { useNavigate } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";

import { deleteAllCookies, buildApiEndpoint } from "Utils";
import { setCookie, getCookie } from "Utils";
import { FaPlus, FaEdit } from "react-icons/fa";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { MdDelete } from "react-icons/md";

const ProfileOverview = () => {
  const {
    login,
    user,
    handleModal,
    showModal,
    handleModal2,
    showModal2,
    showModal3,
    handleModal3,
  } = useAuth();
  const storedUser = getCookie("user"); // localStorage.getItem("user");
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [brand, setBrand] = useState(false);
  const [is2FA, setIs2FA] = useState(
    JSON.parse(storedUser).twoFactorAuthEnabled
  );
  const [transactionDet, setTransactionDet] = useState("");
  const [brandId, setBrandId] = useState("");
  const [brandName, setBrandName] = useState("");
  const [tone, setTone] = useState("");
  const [audience, setAudience] = useState("");
  const [rules, setRules] = useState("");
  const [brandInfo, setBrandInfo] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (storedUser && !user) {
      const user = JSON.parse(storedUser);
      login(user);
    }
  }, [storedUser, login, user]);

  const handleUpdate = async (e) => {
    setIsLoading(true);
    if (!name) {
      setErrorMessage("Please enter name...");
    } else {
      const userId = user._id;
      e.preventDefault();
      const response = await fetch(buildApiEndpoint(`/update-user/${userId}`), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email: user.email }),
        credentials: "include",
      });
      if (response.ok) {
        const userInfo = await response.json();
        login(userInfo);
        handleModal();
        setCookie("user", JSON.stringify(userInfo));
        // localStorage.setItem("user", JSON.stringify(userInfo));
        setIsLoading(false);
      }
    }
  };

  const handleBrandCreate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!brandName || !tone || !audience || !rules) {
      setErrorMessage("Please enter all fields...");
      setIsLoading(false);
    } else {
      const userId = user._id;
      e.preventDefault();
      const response = await fetch(
        buildApiEndpoint(`/create-brand/${userId}`),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: brandName, tone, audience, rules }),
          credentials: "include",
        }
      );
      if (response.ok) {
        const newData = await response.json();
        console.log(newData);
        setBrand(false);
        setRefreshData((prevState) => !prevState);
        setIsLoading(false);
        return;
      } else {
        setErrorMessage("Error! Please try again later");
      }
    }
  };

  const handleGetBrands = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(buildApiEndpoint(`/brands`), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        const brands = await response.json();
        setIsLoading(false);
        return brands;
      } else {
        throw new Error("Failed to fetch brands");
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const brandsData = await handleGetBrands();
        console.log(brandsData);
        let [lastBrand] = brandsData.slice(-1);
        console.log(lastBrand);
        setBrandInfo(lastBrand);
        setBrandId(lastBrand._id);
      } catch (error) {}
    };

    if (initialLoad || refreshData) {
      fetchData();
      setInitialLoad(false);
    }
  }, [initialLoad, refreshData]);

  const handleDelete = async (e) => {
    const userId = user._id;
    setIsLoading(true);
    e.preventDefault();

    const response = await fetch(buildApiEndpoint(`/users/${userId}`), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    setIsLoading(false);

    if (response.status === 204) {
      // localStorage.removeItem("user");
      deleteAllCookies();

      navigate("/auth/sign-in");
    }
  };

  async function handle2FAChange(e) {
    setIsLoading(true);

    const response = await fetch(buildApiEndpoint(`/change2FA`), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        twoFactorAuthEnabled: e.target.checked,
        email: user.email,
      }),
      credentials: "include",
    });

    setIsLoading(false);

    if (response.ok) {
      const userInfo = await response.json();
      login(userInfo);

      setCookie("user", JSON.stringify(userInfo));

      // localStorage.setItem("user", JSON.stringify(userInfo));
      setIs2FA(userInfo.twoFactorAuthEnabled);
    }
  }

  if (!user) {
    navigate("/auth");
  }
  const handleBrand = () => {
    setBrand(true);
  };
  const getTransactionDet = async (e) => {
    try {
      const response = await fetch(buildApiEndpoint("/user/transactions"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        setIsLoading(false);
        // Handle successful response data here
        const transactions = await response.json();
        setTransactionDet(transactions);
        return;
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTransactionDet();
  }, []);

  const handleDeleteBrand = async () => {
    setIsLoading2(true);
    setBrand(false);
    const response = await fetch(buildApiEndpoint("/delete-brand"), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (response.ok) {
      setBrand(false);
      setRefreshData((prevState) => !prevState);
      handleModal3();
      setIsLoading2(false);
    } else {
      setIsLoading2(false);
      handleModal3();
    }
  };

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 2500);
    }
  });

  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString();
  }; // Format as "MM/DD/YYYY"
  const dummyDet = [
    {
      transaction_id: "123456789",
      amount: {
        value: "50.00",
        currency: "USD",
      },
      date: "2024-03-07T12:00:00Z",
      status: "completed",
    },
    {
      transaction_id: "987654321",
      amount: {
        value: "25.00",
        currency: "USD",
      },
      date: "2024-03-06T11:00:00Z",
      status: "completed",
    },
  ];

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-6 lg:!mb-0">
          <Banner />
        </div>

        <div className="col-span-6 lg:!mb-0">
          <div class="!z-5 relative flex h-full w-full flex-col rounded-[20px] bg-white bg-clip-border p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none">
            <div class="mb-8 mt-2 w-full">
              <h4 class="px-2 text-xl font-bold text-navy-700 dark:text-white">
                Two Factor Authentication
              </h4>
              <p class="my-2 px-2 text-base text-gray-600">
                Add an extra layer of security to your account by entering a
                code sent to your email every time you attempt to login
              </p>
              {is2FA ? (
                <Switch defaultChecked onChange={handle2FAChange} />
              ) : (
                <Switch onChange={handle2FAChange} />
              )}
            </div>
            <div class="mb-8 mt-2 w-full ">
              <div className="flex items-center justify-between">
                <h4 class="px-2 text-xl font-bold text-navy-700 dark:text-white">
                  Brand Voice
                </h4>
                {brandInfo ? (
                  <button
                    class="middle none center mr-3 flex items-center justify-center rounded-lg border border-blue-500 px-6 py-3 font-sans text-xs font-bold uppercase text-blue-500 transition-all hover:opacity-75 focus:ring focus:ring-blue-200 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    data-ripple-dark="true"
                    onClick={handleBrand}
                  >
                    Edit brand <FaEdit className="ml-2" />
                  </button>
                ) : (
                  <button
                    class="middle none center mr-3 flex items-center justify-center rounded-lg border border-blue-500 px-6 py-3 font-sans text-xs font-bold uppercase text-blue-500 transition-all hover:opacity-75 focus:ring focus:ring-blue-200 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    data-ripple-dark="true"
                    onClick={handleBrand}
                  >
                    Add brand <FaPlus className="ml-2" />
                  </button>
                )}
              </div>
              <p class="my-2 px-2 text-base text-gray-600">
                Add your brand info so CopyMax can tailor your generated content
                to fit your brand
              </p>
              {brandInfo && (
                <div>
                  <div>
                    <p className="px-2 text-lg font-bold text-[#000]">
                      Brand Name:
                      <span className="ml-3 font-medium text-[#000]">
                        {brandInfo.name}
                      </span>
                    </p>
                    <p className="px-2 text-lg font-bold text-[#000]">
                      Brand Tone:{" "}
                      <span className="ml-3 font-medium text-[#000]">
                        {brandInfo.tone}
                      </span>
                    </p>
                    <p className="px-2 text-lg font-bold text-[#000]">
                      Brand Target Audience:{" "}
                      <span className="ml-3 font-medium text-[#000]">
                        {brandInfo.audience}
                      </span>
                    </p>
                    <p className="px-2 text-lg font-bold text-[#000]">
                      Brand Rules:{" "}
                      <span className="ml-3 font-medium text-[#000]">
                        {brandInfo.rules}
                      </span>
                    </p>
                  </div>
                  <button
                    class="middle none center float-right mr-3 mt-7 flex items-center justify-center rounded-lg border border-red-500 bg-red-500 px-6 py-3 font-sans text-xs font-bold uppercase text-white transition-all hover:opacity-75 focus:ring focus:ring-blue-200 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    data-ripple-dark="true"
                    onClick={handleModal3}
                  >
                    Delete brand <MdDelete className="ml-2" />
                  </button>
                  {isLoading && (
                    <div className="absolute left-0 top-0 flex h-screen w-full items-center justify-center">
                      <ScaleLoader
                        loading={isLoading}
                        size={70}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        color="green"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-span-12 lg:!mb-0">
          <div class="!z-5 relative flex h-full w-full flex-col rounded-[20px] bg-white bg-clip-border p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none">
            <h3 className="text-lg font-bold text-navy-900">
              Transaction history
            </h3>
            <div className="overflow-x-auto">
              {transactionDet && (
                <table className="table">
                  {/* head */}
                  <thead>
                    <tr className="text-base text-navy-900">
                      <th>ID</th>
                      <th>Value</th>
                      <th>Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  {transactionDet.map((det) => {
                    return (
                      <tbody>
                        <tr className="text-base text-navy-900">
                          <th>{det.transaction_id}</th>
                          <td>${det.amount.value}</td>
                          <td>{formattedDate(det.date)}</td>
                          <td>{det.status}</td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              )}
            </div>
          </div>
        </div>
        {showModal && (
          <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-white bg-opacity-70 outline-none focus:outline-none">
              <div className="relative mx-auto my-6 w-auto max-w-3xl">
                {/*content*/}
                <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                  {/*header*/}
                  <div className="border-slate-200 flex items-start justify-between rounded-t border-b border-solid p-5">
                    <h3 className="text-3xl font-semibold text-navy-900">
                      Edit Profile
                    </h3>
                    <button
                      className="text-black float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none opacity-5 outline-none focus:outline-none"
                      onClick={handleModal}
                    >
                      <span className="text-black block h-6 w-6 bg-transparent text-2xl opacity-5 outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  {errorMessage && (
                    <p className="text-center font-medium text-red-600">
                      {errorMessage}
                    </p>
                  )}
                  <div className="relative flex-auto px-6">
                    <label className="text-navy-900">Name</label>
                    <input
                      type="text"
                      className="border-slate-300 my-4 w-full rounded-lg border-2 bg-white px-2.5 py-3 text-navy-900"
                      onChange={(e) => {
                        setName(e.target.value);
                        setErrorMessage("");
                      }}
                      placeholder="John"
                    />
                  </div>
                  {/*footer*/}
                  <div className="border-slate-200 flex items-center justify-end rounded-b border-t border-solid p-6">
                    <button
                      className="background-transparent mb-1 mr-1 px-6 py-2 text-sm font-bold uppercase text-red-500 outline-none transition-all duration-150 ease-linear focus:outline-none"
                      type="button"
                      onClick={handleModal}
                    >
                      Close
                    </button>
                    <button
                      className="mb-1 mr-1 rounded bg-blue-500 px-6 py-3 text-sm font-bold uppercase text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg focus:outline-none active:bg-blue-600"
                      type="button"
                      onClick={handleUpdate}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
              {isLoading && (
                <div className="absolute left-0 top-0 flex h-screen w-full items-center justify-center">
                  <ScaleLoader
                    loading={isLoading}
                    size={70}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    color="green"
                  />
                </div>
              )}
            </div>
            <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
          </>
        )}

        {showModal2 ? (
          <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-white bg-opacity-70 outline-none focus:outline-none">
              <div className="relative mx-auto my-6 w-auto max-w-3xl">
                {/*content*/}
                <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                  {/*header*/}
                  <div className="border-slate-200 flex items-start justify-between rounded-t border-b border-solid p-5">
                    <h3 className="text-3xl font-semibold text-navy-900">
                      Delete Profile
                    </h3>
                    <button
                      className="text-black float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none opacity-5 outline-none focus:outline-none"
                      onClick={handleModal2}
                    >
                      <span className="text-black block h-6 w-6 bg-transparent text-2xl opacity-5 outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  <p className="font-medum p-4 text-center text-lg text-navy-900">
                    Are you sure you want to delete your account?
                  </p>
                  <div className="border-slate-200 flex items-center justify-end rounded-b border-t border-solid p-6">
                    <button
                      className="background-transparent mb-1 mr-1 px-6 py-2 text-sm font-bold uppercase text-red-500 outline-none transition-all duration-150 ease-linear focus:outline-none"
                      type="button"
                      onClick={handleModal2}
                    >
                      Close
                    </button>
                    <button
                      className="mb-1 mr-1 rounded bg-red-500 px-6 py-3 text-sm font-bold uppercase text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg focus:outline-none active:bg-red-600"
                      type="button"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              {isLoading && (
                <div className="absolute left-0 top-0 flex h-screen w-full items-center justify-center">
                  <ScaleLoader
                    loading={isLoading}
                    size={70}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    color="green"
                  />
                </div>
              )}
            </div>
            <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
          </>
        ) : null}
        {showModal3 ? (
          <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-white bg-opacity-70 outline-none focus:outline-none">
              <div className="relative mx-auto my-6 w-auto max-w-3xl">
                {/*content*/}
                <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                  {/*header*/}
                  <div className="border-slate-200 flex items-start justify-between rounded-t border-b border-solid p-5">
                    <h3 className="text-3xl font-semibold text-navy-900">
                      Delete Brand Info
                    </h3>
                    <button
                      className="text-black float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none opacity-5 outline-none focus:outline-none"
                      onClick={handleModal2}
                    >
                      <span className="text-black block h-6 w-6 bg-transparent text-2xl opacity-5 outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  <p className="font-medum p-4 text-center text-lg text-navy-900">
                    Are you sure you want to delete this brand info?
                  </p>
                  <div className="border-slate-200 flex items-center justify-end rounded-b border-t border-solid p-6">
                    <button
                      className="background-transparent mb-1 mr-1 px-6 py-2 text-sm font-bold uppercase text-red-500 outline-none transition-all duration-150 ease-linear focus:outline-none"
                      type="button"
                      onClick={handleModal3}
                    >
                      Close
                    </button>
                    <button
                      className="mb-1 mr-1 rounded bg-red-500 px-6 py-3 text-sm font-bold uppercase text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg focus:outline-none active:bg-red-600"
                      type="button"
                      onClick={handleDeleteBrand}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              {isLoading2 && (
                <div className="absolute left-0 top-0 flex h-screen w-full items-center justify-center">
                  <ScaleLoader
                    loading={isLoading2}
                    size={70}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    color="green"
                  />
                </div>
              )}
            </div>
            <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
          </>
        ) : null}
      </div>
      {brand ? (
        <div className="mt-3 w-full grid-cols-12">
          <form>
            <div class="!z-5 relative col-span-12 flex h-full w-full flex-col rounded-[20px] bg-white bg-clip-border p-3 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none">
              <InputField
                label="Brand Name"
                type="text"
                onChange={(e) => {
                  setBrandName(e.target.value);
                }}
              />
              <InputField
                label="Brand Tone"
                type="text"
                onChange={(e) => {
                  setTone(e.target.value);
                }}
              />
              <InputField
                label="Target Audiences"
                type="text"
                onChange={(e) => {
                  setAudience(e.target.value);
                }}
              />
              <TextField
                label="Brand Rules"
                onChange={(e) => {
                  setRules(e.target.value);
                }}
              />
              <div className="w-full">
                {errorMessage && (
                  <p className="text-center text-base font-semibold text-red-500">
                    {errorMessage}
                  </p>
                )}
                <button
                  className="float-right mr-3 mt-5 w-fit rounded-lg bg-green-500 px-6 py-3 font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  data-ripple-light="true"
                  onClick={handleBrandCreate}
                >
                  {isLoading ? "Saving changes" : " Save changes"}
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
      {/* all project & ... */}
    </div>
  );
};

export default ProfileOverview;
