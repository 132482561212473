import { Link, Routes, Route, Navigate } from "react-router-dom";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import SignIn from "../../views/auth/SignIn";
import NoPage from "views/NoPage";
import SignUp from "views/auth/signUp";
import FinishAccountSetup from "views/auth/finishAccountSetup";
import ForgotPword from "views/forgotpword";
import ResetPword from "views/resetpword";

export default function Auth() {
  
  document.documentElement.dir = "ltr";

  return (
    <div>
      <div className="relative h-full w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-fit w-full flex-col pt-12 md:pt-0 md:max-w-[75%] h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:pl-0 xl:max-w-full">
                <Routes>

                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />

                  <Route path="/sign-in" element={<SignIn/>} />
                  <Route path="/signup" element={<SignUp/>} />
                  <Route path="/forgot-password" element={<ForgotPword/>} />
                  <Route path="/reset-password/:rpHash" element={<ResetPword/>} />
                  <Route path="/email/verify/:sessHash" element={<FinishAccountSetup/>} />

                  <Route path="*" element={<NoPage />} />
                </Routes>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
