import InputField from "components/fields/InputField";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import ScaleLoader from "react-spinners/ScaleLoader";
import Alert from "components/Alert";

import { buildApiEndpoint } from "Utils";

const ForgotPword = () => {
  const [email, setEmail] = useState("");
  const [newMsg, setNewMsg] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const resetPass = async function () {
    if (!email) {
      setNewMsg({ msg: "Enter your email address", type: "danger" });
    } else if (
      !email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setNewMsg({ msg: "Please enter a valid email address", type: "danger" })
    } else {
      setIsLoading(true);

      const lowercaseEmail = email.toLowerCase();
      try {
        const response = await fetch(buildApiEndpoint("/forgot-password"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({ email: lowercaseEmail }),
          credentials: "include"
        });

        setIsLoading(false);

        if (response.ok) {
          setNewMsg({
            msg: "Reset link has been sent to your email",
            type: "success"
          })
        } else {
          const responseData = await response.text();
          setNewMsg({
            msg: responseData,
            type: "danger"
          });
        }
      } catch (error) {
        setIsLoading(false);

        setNewMsg({ msg: "Check your connection and try again later", type: 'danger' });

        console.error("Login error:", error);
      }
    }
  }

  return (
    <div className="w-full h-full flex justify-center items-center mt-6">
      <div className="items-center flex-col w-full md:w-[500px] h-full p-[16px] bg-cover mt-16 flex flex-col items-center">
        <h4 className="text-navy-700 text-3xl font-bold dark:text-white">Reset password</h4>
        {newMsg.msg && <Alert message={newMsg.msg} type={newMsg.type} />}
        <h5 className="text-base font-normal text-gray-600 text-center mt-4"> Enter the email address you use to create an account</h5>
        <InputField
          variant="auth"
          extra="mb-3 text-navy-900 w-full"
          placeholder="email@example.com"
          type="email"
          onChange={(e) => {
            setNewMsg({ msg: '' })
            setEmail(e.target.value)
          }}
        />
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={resetPass}
        >
          Reset password
        </button>

        <Link
          to="/auth/signup"
          className="mt-4 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white self-start"
        >
          Sign up instead
        </Link>
      </div>

      {isLoading && (
        <div className="absolute top-0 left-0 flex h-[120vh] w-full items-center justify-center overflow-hidden bg-[#000] bg-opacity-50">
          <ScaleLoader
            loading={isLoading}
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
            color="green"
          />
        </div>
      )}
    </div>
  );
};

export default ForgotPword;
