import { TbBusinessplan, TbCursorText } from "react-icons/tb";
import {
  FaTwitter,
  FaFileInvoiceDollar,
  FaLinkedin,
  FaProductHunt,
  FaRegNewspaper,
  FaYoutube,
  FaQuestionCircle,
  FaFilm,
  FaFileAlt,
  FaQuoteLeft,
  FaRegCalendarAlt,
  FaUserAlt,
  FaStar,
  FaMicrophoneAlt,
  FaChartLine,
  FaFileSignature,
  FaEnvelopeOpenText,
  FaLightbulb,
  FaBuilding,
  FaFile,
  FaChild,
  FaVideo,
} from "react-icons/fa";
import { RiChatQuoteFill, RiTvFill } from "react-icons/ri";
import { GrMail } from "react-icons/gr";
import { AiFillInstagram, AiFillPushpin } from "react-icons/ai";
import { SlEnvolopeLetter } from "react-icons/sl";
import { MdAttachEmail } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";

import Widget from "components/widget/Widget";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "authContext";
import { useNavigate } from "react-router-dom";
import { getCookie } from "Utils";

const Dashboard = () => {
  const { login, user, opend, setOpend } = useAuth();
  const storedUser = getCookie("user"); // localStorage.getItem("user");
  const navigate = useNavigate();
  const [category, setCategory] = useState("All Templates");
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (storedUser && !user) {
      const user = JSON.parse(storedUser);
      login(user);
    }
  }, [storedUser, login, user]);

  if (!user) {
    navigate("/auth");
  }

  const openDropdrown = () => {
    setOpend(!opend);
  };

  const [templates, setTemplates] = useState([
    // Define your templates here with 'category' property
    {
      id: 1,
      title: "Blank Document",
      category: "All Templates",
      icon: <AiOutlinePlus className="h-7 w-7" />,
      subtitle: "",
      link: "/generator/blank",
    },
    {
      id: 2,
      title: "Letter Generator",
      category: "Writing and Creativity",
      icon: <SlEnvolopeLetter className="h-7 w-7" />,
      subtitle:
        "Unlock your creativity and efficiency with our letter generator. Seamlessly craft unique and persuasive letters for any occasion.",
      link: "/generator/letter",
    },
    {
      id: 3,
      title: "Email Generator",
      category: "Social Media and Communication",
      icon: <MdAttachEmail className="h-7 w-7" />,
      subtitle:
        "Streamline your communications with our advanced email content generation tool.",
      link: "/generator/email",
    },
    {
      id: 4,
      title: "Business Plan",
      category: "Business and Planning",
      icon: <TbBusinessplan className="h-7 w-7" />,
      subtitle:
        "Generate tailored business strategies effortlessly with our user-friendly tool.",
      link: "/generator/business",
    },
    {
      id: 5,
      title: "Marketing Plan",
      category: "Business and Planning",
      icon: <AiFillPushpin className="h-6 w-6" />,
      subtitle:
        "Effortlessly create strategic marketing plans using our user-friendly tool.",
      link: "/generator/marketing",
    },
    {
      id: 6,
      title: "Tweet Generator",
      category: "Social Media and Communication",
      icon: <FaTwitter className="h-7 w-7" />,
      subtitle:
        "Generate engaging tweets effortlessly with our tweet generator tool.",
      link: "/generator/tweet",
    },
    {
      id: 7,
      title: "Invoice Generator",
      category: "Business and Planning",
      icon: <FaFileInvoiceDollar className="h-6 w-6" />,
      subtitle:
        "Generate professional invoices in seconds with our invoice generator tool. Streamline your billing process today.",
      link: "/generator/invoice",
    },
    {
      id: 8,
      title: "Quote Generator",
      category: "Miscellanous",
      icon: <RiChatQuoteFill className="h-7 w-7" />,
      subtitle:
        "Generate inspiring quotes at your fingertips with our quote generator tool. Share wisdom and motivation with ease.",
      link: "/generator/quote",
    },
    {
      id: 9,
      title: "Proposal Generator",
      category: "Business and Planning",
      icon: <GrMail className="h-6 w-6" />,
      subtitle:
        "Craft compelling proposals effortlessly with our proposal generator tool. Win over clients and partners with professionalism and ease.",
      link: "/generator/proposal",
    },
    {
      id: 10,
      title: "Insta Caption Generator",
      category: "Social Media and Communication",
      icon: <AiFillInstagram className="h-6 w-6" />,
      subtitle:
        "Elevate your Instagram posts with our Insta Caption generator. Find the perfect words to capture your moments.",
      link: "/generator/insta",
    },
    {
      id: 11,
      title: "Text Mixture",
      category: "Miscellanous",
      icon: <TbCursorText className="h-6 w-6" />,
      subtitle:
        "Rephrase/reword your contents. Generate a more compelling, powerful version of your content with our AI model.",
      link: "/generator/textmixture",
    },
    {
      id: 12,
      title: "Ads Generator",
      category: "Marketing and Promotion",
      icon: <RiTvFill className="h-6 w-6" />,
      subtitle:
        "Generate Ads for your social media. This AI can generate ads for either Facebook, WhatsApp, Twitter, and so on.",
      link: "/generator/ads",
    },
    {
      id: 15,
      title: "Youtube Video Script Generator",
      category: "Writing and Creativity",
      icon: <FaYoutube className="h-6 w-6" />,
      subtitle:
        "Generate engaging scripts for your YouTube videos. Tailor the content to your audience, convey the right tone, and details to keep viewers hooked.",
      link: "/generator/youtube-video-script",
    },
    {
      id: 16,
      title: "FAQs Generator",
      category: "Knowledge and Information",
      icon: <FaQuestionCircle className="h-6 w-6" />,
      subtitle:
        "Effortlessly craft FAQs for your product or service. Answer common questions, engage your audience, and provide valuable information in a clear and concise manner.",
      link: "/generator/faq",
    },
    {
      id: 17,
      title: "Movie Script Generator",
      category: "Writing and Creativity",
      icon: <FaFilm className="h-6 w-6" />,
      subtitle:
        "Bring your stories to life with our movie script generator. Craft compelling dialogues, scenes, and narratives for your next cinematic masterpiece.",
      link: "/generator/movie-script",
    },
    {
      id: 18,
      title: "Document Abstract Generator",
      category: "Miscellaneous",
      icon: <FaFileAlt className="h-6 w-6" />, // Suggested icon: FaFileAlt
      subtitle:
        "Summarize lengthy documents with ease. Generate concise and informative abstracts that capture the essence of your content.",
      link: "/generator/document-abstract",
    },
    {
      id: 19,
      title: "Slogan Generator",
      category: "Miscellaneous",
      icon: <FaQuoteLeft className="h-6 w-6" />, // Suggested icon: FaQuoteLeft
      subtitle:
        "Craft memorable slogans and taglines for your brand. Make a lasting impression with catchy and impactful phrases that resonate with your audience.",
      link: "/generator/slogan",
    },
    {
      id: 20,
      title: "Event Invitation Generator",
      category: "Marketing and Promotion",
      icon: <FaRegCalendarAlt className="h-6 w-6" />, // Suggested icon: FaRegCalendarAlt
      subtitle:
        "Create captivating event invitations. Whether it's a formal gathering or a casual celebration, craft invitations that leave a lasting impression.",
      link: "/generator/event-invitation",
    },
    {
      id: 21,
      title: "Social Media Bio Generator",
      category: "Social Media and Communication",
      icon: <FaUserAlt className="h-6 w-6" />, // Suggested icon: FaUserAlt
      subtitle:
        "Optimize your social media profiles with our bio generator. Craft the best bios that reflect your personality/brand in the best light.",
      link: "/generator/social-media-bio",
    },
    {
      id: 23,
      title: "Interview Questions Generator",
      category: "Business and Planning",
      icon: <FaMicrophoneAlt className="h-6 w-6" />, // Suggested icon: FaMicrophoneAlt
      subtitle:
        "Prepare for interviews with our question generator. Craft thought-provoking and relevant questions that help you assess candidates effectively.",
      link: "/generator/interview-questions",
    },
    {
      id: 24,
      title: "Elevator Pitch Generator",
      category: "Business and Planning",
      icon: <FaChartLine className="h-6 w-6" />, // Suggested icon: FaChartLine
      subtitle:
        "Clearly communicate your value proposition and make a memorable impression in a short span of time.",
      link: "/generator/elevator-pitch",
    },
    {
      id: 26,
      title: "Email Subject Line Generator",
      category: "Miscellaneous",
      icon: <FaEnvelopeOpenText className="h-6 w-6" />, // Suggested icon: FaEnvelopeOpenText
      subtitle:
        "Boost email open rates with our AI Generator. Get attention-grabbing and relevant subject lines that encourage recipients to click and read.",
      link: "/generator/email-subject",
    },
    {
      id: 27,
      title: "Content Ideas Generator",
      category: "Writing and Creativity",
      icon: <FaLightbulb className="h-6 w-6" />, // Suggested icon: FaLightbulb
      subtitle:
        "Stuck on what to create? Use our content ideas generator for inspiration. Generate creative and relevant content ideas that fit your niche.",
      link: "/generator/content-ideas",
    },
    {
      id: 28,
      title: "Business Name Generator",
      category: "Business and Planning",
      icon: <FaBuilding className="h-6 w-6" />, // Suggested icon: FaBuilding
      subtitle:
        "Find the perfect name for your business. Our generator suggests unique & memorable business names that align with your brand vision.",
      link: "/generator/business-name",
    },
    {
      id: 29,
      title: "Summarizer",
      category: "Knowledge and Information",
      icon: <FaFile className="h-6 w-6" />, // Suggested icon: FaFileAlt
      subtitle:
        "Effortlessly summarize lengthy texts. Use our AI-powered summarizer to condense information while retaining the key points and insights.",
      link: "/generator/summarizer",
    },
    {
      id: 30,
      title: "Explain to a Child",
      category: "Knowledge and Information",
      icon: <FaChild className="h-6 w-6" />, // Suggested icon: FaChild
      subtitle:
        "Simplify complex concepts. Our tool helps you explain ideas, topics, or projects in a way that a child can understand, making your communication clear and accessible.",
      link: "/generator/explain-to-a-child",
    },
    {
      id: 31,
      title: "Commercial Video Ad Script Generator",
      category: "Writing and Creativity",
      icon: <FaVideo className="h-6 w-6" />, // Suggested icon: FaVideo
      subtitle:
        "Craft compelling scripts for video ads. Generate engaging and persuasive content that captures attention and communicates your message.",
      link: "/generator/commercial-video-ad",
    },
    {
      id: 32,
      title: "Linkedin Post Generator",
      category: "Social Media and Communication",
      icon: <FaLinkedin className="h-6 w-6" />, // Suggested icon: FaLinkedin
      subtitle: "Get professional or friendlly content for your post.",
      link: "/generator/linkedin",
    },
    // Add more templates....
  ]);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const filterTemplatesByCategory = (selectedCategory) => {
    setCategory(selectedCategory);
    setOpend(false);
    setSearchInput("");
  };

  const filterTemplatesBySearch = (template) => {
    return (
      template.title.toLowerCase().includes(searchInput.toLowerCase()) ||
      template.subtitle.toLowerCase().includes(searchInput.toLowerCase())
    );
  };

  const filteredTemplates =
    category === "All Templates"
      ? templates.filter(filterTemplatesBySearch)
      : templates.filter(
          (template) =>
            template.category === category && filterTemplatesBySearch(template)
        );

  const categories = [
    {
      id: 1,
      name: "All Templates",
    },
    {
      id: 2,
      name: "Business and Planning",
    },
    {
      id: 3,
      name: "Social Media and Communication",
    },
    {
      id: 4,
      name: "Miscellanous",
    },
    {
      id: 5,
      name: "Marketing and Promotion",
    },
    {
      id: 6,
      name: "Writing and Creativity",
    },
    {
      id: 7,
      name: "Knowledge and Information",
    },
  ];
  const handleSearchInputKeyPress = (e) => {
    if (e.key === "Enter") {
      setOpend(false);
    }
  };

  return (
    <div className="">
      <div className="w-[240px]">
        <div className="group relative">
          <button
            id="dropdown-button"
            className="z-[99] inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
            onClick={openDropdrown}
          >
            <span className="mr-2">{`${category}`}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="-mr-1 ml-2 h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {opend && (
            <div
              id="dropdown-menu"
              className="ring-black absolute right-0 z-[99] mt-2 space-y-1 rounded-md bg-white p-1 shadow-lg ring-1 ring-opacity-5"
            >
              <input
                id="search-input"
                className="block w-full rounded-md border border-blue-300 bg-white px-4 py-2 font-medium text-gray-800 focus:outline-none"
                type="text"
                placeholder="Search Templates"
                autoComplete="off"
                onChange={handleSearchInputChange}
                onKeyPress={handleSearchInputKeyPress}
              />
              {categories.map((category) => (
                <a
                  href="#"
                  className="block cursor-pointer rounded-md px-4 py-2 font-bold text-gray-900 hover:bg-gray-100 active:bg-blue-100 "
                  key={category.id}
                  onClick={() => filterTemplatesByCategory(category.name)}
                >
                  {category.name}
                </a>
              ))}
            </div>
          )}
        </div>
      </div>

      <div
        className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"
        onClick={() => setOpend(false)}
      >
        {filteredTemplates.length > 0 ? (
          filteredTemplates.map((template) => (
            <Link to={template.link} key={template.id}>
              <Widget
                icon={template.icon}
                title={template.title}
                subtitle={template.subtitle}
              />
            </Link>
          ))
        ) : (
          <div className="flex w-screen items-center justify-center">
            <h1>No Template found...</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
