import React from "react";

// Admin Imports
import MainDashboard from "views/home/dashboard";
import NFTMarketplace from "views/home/projects";
import Profile from "views/home/profile";
import Shared from "views/home/shared";

import Marketing from "views/chatgptgenerator/marketing";
import Linkedin from "views/chatgptgenerator/linkedin";
import Ads from "views/chatgptgenerator/ads";
import TextMixture from "views/chatgptgenerator/textmixture";
import Quote from "views/chatgptgenerator/quote";
import Tweet from "views/chatgptgenerator/tweet";
import Insta from "views/chatgptgenerator/insta";
import Proposal from "views/chatgptgenerator/proposal";
import Invoice from "views/chatgptgenerator/invoice";
import Email from "views/chatgptgenerator/email";
import Letter from "views/chatgptgenerator/letter";
import Blank from "views/chatgptgenerator/blank";
import Pricing from "views/pricing";
import TinyMice from "views/home/TinyMice";

// Icon Imports
import { MdHome, MdPerson, MdLock } from "react-icons/md";

import { BsStack } from "react-icons/bs";
import Business from "views/chatgptgenerator/business";
import BusinessName from "views/chatgptgenerator/business-name";
import CommercialVideoAdScript from "views/chatgptgenerator/commercial-video-ad";
import { BsShareFill } from "react-icons/bs";
import ContentIdeas from "views/chatgptgenerator/content-ideas";
import DocumentAbstract from "views/chatgptgenerator/document-abstract";
import ElevatorPitch from "views/chatgptgenerator/elevator-pitch";
import EmailSubjectLine from "views/chatgptgenerator/email-subject";
import EventInvitation from "views/chatgptgenerator/event-invitation";
import Interview from "views/chatgptgenerator/interview-questions";
import Movie from "views/chatgptgenerator/movie-script";
import Slogan from "views/chatgptgenerator/slogan";
import Social from "views/chatgptgenerator/social-media-bio";
import Summarizer from "views/chatgptgenerator/summarizer";
import Youtube from "views/chatgptgenerator/youtube-video-script";
import Faq from "views/chatgptgenerator/faq";
import Child from "views/chatgptgenerator/explain-to-a-child";

const routes = [
  {
    name: "All Templates",
    layout: "/home",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "My Contents",
    layout: "/home",
    path: "projects",
    icon: <BsStack className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Shared with me",
    layout: "/home",
    path: "shared",
    icon: <BsShareFill className="h-6 w-6" />,
    component: <Shared />,
    secondary: true,
  },
  {
    name: "Profile",
    layout: "/home",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Marketing Plan",
    layout: "/generator",
    path: "marketing",
    component: <Marketing />,
    hidden: true,
  },
  {
    name: "Elevator Pitch",
    layout: "/generator",
    path: "elevator-pitch",
    component: <ElevatorPitch />,
    hidden: true,
  },
  {
    name: "Document Abstract",
    layout: "/generator",
    path: "document-abstract",
    component: <DocumentAbstract />,
    hidden: true,
  },
  {
    name: "Content Ideas",
    layout: "/generator",
    path: "content-ideas",
    component: <ContentIdeas />,
    hidden: true,
  },
  {
    name: "Business Plan",
    layout: "/generator",
    path: "business",
    component: <Business />,
    hidden: true,
  },
  {
    name: "LinkedIn",
    layout: "/generator",
    path: "linkedin",
    component: <Linkedin />,
    hidden: true,
  },
  {
    name: "Ads",
    layout: "/generator",
    path: "ads",
    component: <Ads />,
    hidden: true,
  },
  {
    name: "Text mixture",
    layout: "/generator",
    path: "textmixture",
    component: <TextMixture />,
    hidden: true,
  },
  {
    name: "Quote",
    layout: "/generator",
    path: "quote",
    component: <Quote />,
    hidden: true,
  },
  {
    name: "Tweet",
    layout: "/generator",
    path: "tweet",
    component: <Tweet />,
    hidden: true,
  },
  {
    name: "Insta",
    layout: "/generator",
    path: "insta",
    component: <Insta />,
    hidden: true,
  },
  {
    name: "Proposal",
    layout: "/generator",
    path: "proposal",
    component: <Proposal />,
    hidden: true,
  },
  {
    name: "Invoice",
    layout: "/generator",
    path: "invoice",
    component: <Invoice />,
    hidden: true,
  },
  {
    name: "Commercial Video Script",
    layout: "/generator",
    path: "commercial-video-ad",
    component: <CommercialVideoAdScript />,
    hidden: true,
  },
  {
    name: "Pricing",
    layout: "/pricing",
    path: "",
    component: <Pricing />,
    hidden: true,
  },
  {
    name: "Blank document",
    layout: "/generator",
    path: "blank",
    component: <Blank />,
    hidden: true,
  },
  {
    name: "Email",
    layout: "/generator",
    path: "email",
    component: <Email />,
    hidden: true,
  },
  {
    name: "Letter",
    layout: "/generator",
    path: "letter",
    component: <Letter />,
    hidden: true,
  },
  {
    name: "Event Invitation",
    layout: "/generator",
    path: "event-invitation",
    component: <EventInvitation />,
    hidden: true,
  },
  {
    name: "Summarizer",
    layout: "/generator",
    path: "summarizer",
    component: <Summarizer />,
    hidden: true,
  },
  {
    name: "Youtube Video Script",
    layout: "/generator",
    path: "youtube-video-script",
    component: <Youtube />,
    hidden: true,
  },
  {
    name: "Slogan/Tagline Generator",
    layout: "/generator",
    path: "slogan",
    component: <Slogan />,
    hidden: true,
  },
  {
    name: "Social Media Bio",
    layout: "/generator",
    path: "social-media-bio",
    component: <Social />,
    hidden: true,
  },
  {
    name: "Movie Script",
    layout: "/generator",
    path: "movie-script",
    component: <Movie />,
    hidden: true,
  },
  {
    name: "Interview Questions",
    layout: "/generator",
    path: "interview-questions",
    component: <Interview />,
    hidden: true,
  },
  {
    name: "FAQ Generator",
    layout: "/generator",
    path: "faq",
    component: <Faq />,
    hidden: true,
  },
  // temporary
  {
    name: "TinyMice",
    layout: "/home",
    path: "tinymice",
    component: <TinyMice />,
    hidden: true,
  },
  {
    name: "Business Name",
    layout: "/generator",
    path: "business-name",
    component: <BusinessName />,
    hidden: true,
  },
  {
    name: "Explain to a child",
    layout: "/generator",
    path: "explain-to-a-child",
    component: <Child />,
    hidden: true,
  },
  {
    name: "Email Subject",
    layout: "/generator",
    path: "email-subject",
    component: <EmailSubjectLine />,
    hidden: true,
  },
];

export default routes;
