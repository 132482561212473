import React from "react";
import { MdHome } from "react-icons/md";
import Blogs from "./views/admin/blogs";
import CreateBlog from "./views/admin/create-blog";

const adminroutes = [
  {
    name: "Blogs",
    layout: "/admin",
    path: "blogs",
    icon: <MdHome className="h-6 w-6" />,
    component: <Blogs />,
  },
  {
    name: "Create Blog",
    layout: "/admin",
    path: "create-blog",
    icon: <MdHome className="h-6 w-6" />,
    component: <CreateBlog />,
  },
];
export default adminroutes;
