/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";

import { useAuth } from "authContext";

import routes from "routes.js";
import FreeCard from "./components/SidebarCard";
import { useEffect, useState } from "react";
import Card from "components/card";
import { IoCard } from "react-icons/io5";
import Logo from "../../assets/img/logo.png";
import Logob from "../../assets/img/logob.png";

import { getCookie, buildApiEndpoint } from "Utils";

const Sidebar = ({ open, onClose }) => {
  const { user, bgdark } = useAuth();
  const [showPricing, setShowPricing] = useState(true);

  const filteredRoutes = routes.filter(
    (route) => route.path !== "sign-in" && !route.hidden
  );

  useEffect(() => {
    if (user) {
      if (user.subscription !== "free") {
        setShowPricing(false);
      }
    }
  }, []);

  const handlePortal = async (e) => {
    if (customerId) {
      const portal = await fetch(buildApiEndpoint("/portal"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customerId }),
      });

      if (portal) {
        const response2 = await portal.json();
        window.open(response2.url, "_self");
      }
    }
  };

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white px-4 pb-10 shadow-2xl shadow-xl shadow-blue-500/50 shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className="mx-auto flex h-full w-full items-center justify-center ">
        {bgdark ? (
          <img src={Logob} alt="logo" className="w-[200px] " />
        ) : (
          <img src={Logo} alt="logo" className="w-[200px] " />
        )}
      </div>
      <div class=" mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className=" pt-1">
        <Links onPress={onClose} routes={filteredRoutes} />

        {showPricing ? (
          <FreeCard className="mt-[30px]" onPress={onClose} />
        ) : (
          <div
            className="flex w-full items-center px-8 font-medium text-gray-600"
            onClick={handlePortal}
          >
            <IoCard className="mr-4" />
            Manage subscription
          </div>
        )}
      </ul>

      {/* Free Horizon Card */}

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
