import React, { useEffect, useState } from "react";
import InputField from "components/fields/InputField";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "components/Alert";
import ScaleLoader from "react-spinners/ScaleLoader";

import { buildApiEndpoint } from "Utils";

const ResetPassword = () => {
  const { rpHash } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [invalid, setInvalid] = useState(false);
  const [confirmed, setConfirmed] = useState(false);


  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (!password || !cpassword) return setErrorMessage("Passwords cannot be empty");

    if (password !== cpassword) {
      return setErrorMessage("Passwords do not match");
    }

    if (password.length < 6) return setErrorMessage("Password should be a minimum of 6 characters long");

    setIsLoading(true)

    const response = await fetch(buildApiEndpoint("/reset-password"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ rpSessHash: rpHash, newPassword: password }),
        credentials: "include"
      }
    );

    setIsLoading(false)

    if (response.status === 200) {
      localStorage.setItem("resetP", true);

      navigate("/auth/sign-in");
    } else {
      setErrorMessage(response.text());
    }

  };

  useEffect(() => {
    const verifyHash = async () => {
      try {
        const response = await fetch(buildApiEndpoint(`/reset-password/verify/${rpHash}`), {
          method: "POST"
        })

        if (response.ok) {
          setConfirmed(true);
          setIsLoading(false)
        } else setInvalid(true);
      } catch (error) {
        console.error("Error verifying session hash:", error);
      }
    };

    verifyHash();
  }, []);



  if (invalid) {
    navigate("/auth/sign-in")
  }

  return (
    <React.Fragment>
      {confirmed && (
        <div className="mt-16 flex h-fit w-full items-center justify-center px-2 md:mx-0 md:px-0  lg:items-center ">
          {/* Finish acct section */}
          <div className=" h-fit w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 sm:max-w-[420px]">
            <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
              Change Password
            </h4>
            <p className="mt-5 mb-9 ml-1 text-base text-gray-600">
              Enter your new password
            </p>

            {errorMessage && <Alert message={errorMessage} type="danger" />}

            {/* Password */}
            <InputField
              variant="auth"
              extra="mb-3 text-navy-900"
              label="New Password*"
              placeholder="A very stong password"
              id="password"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorMessage("");
              }}
            />

            {/* Confirm Password */}
            <InputField
              variant="auth"
              extra="mb-3 text-navy-900"
              label="Confirm password*"
              placeholder="Confirm password"
              id="confirmPassword"
              type="password"
              onChange={(e) => {
                setCpassword(e.target.value);
                setErrorMessage("");
              }}
            />

            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={handleResetPassword}
            >
              Change
            </button>
          </div>
          {isLoading && (
            <div className="absolute top-0 left-0 flex h-[120vh] w-full items-center justify-center bg-[#000] bg-opacity-50">
              <ScaleLoader
                loading={isLoading}
                size={70}
                aria-label="Loading Spinner"
                data-testid="loader"
                color="green"
              />
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default ResetPassword;
