import Card from "components/card";
import React, { useEffect, useState } from "react";
import Loader from "components/Loader";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate, useParams, Link } from "react-router-dom";
import { BiTrash, BiClipboard, BiDownload } from "react-icons/bi";
import { buildApiEndpoint } from "Utils";
import Alert from "components/Alert";
import { getCookie } from "Utils";
import Dropdown from "components/dropdown";
import { saveAs } from "file-saver";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { PDFDocument, rgb } from "pdf-lib";
import { IoPersonAdd } from "react-icons/io5";
import ScaleLoader from "react-spinners/ScaleLoader";

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ExportWord from '@ckeditor/ckeditor5-export-word/src/exportword';
// import ExportPdf from '@ckeditor/ckeditor5-export-pdf/src/exportpdf';

import {
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
} from "react-share";

import { EmailIcon } from "react-share";
import AIOutput from "components/AIOutput";
import { convertToHtml } from "Utils";

// const editorConfiguration = {
//   plugins: [ExportWord, ExportPdf,], // Add other plugins as needed
//   toolbar: {
//     items: ['exportWord', 'exportPdf', '|',], // Add other toolbar items as needed
//   },
// };

const ProjectDetails = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const navigate = useNavigate();
  // duplicated from master layout
  const storedUser = getCookie("user");
  if (storedUser == null || !storedUser) window.location = "/auth/sign-in";
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [Html, setHtml] = useState(false);
  const [email, setEmail] = useState("");
  const [projectIds, setProjectIds] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [newResponse, setNewResponse] = useState("");
  const [editorState, setEditorState] = useState("");
  const [Emailbody, setEmailbody] = useState("");
  const [showModal2, setShowModal2] = useState(false);

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    setResponse(newEditorState);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await fetch(
      buildApiEndpoint(`/delete-project/${projectId}`),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    setIsLoading(false);

    if (response.status === 200) {
      navigate("/home/projects");
    }
  };

  const handleCopy = () => {
    copyToClipboard(project.response);
    setMessage("Copied to clipboard successfully");

    setTimeout(() => setMessage(""), 3000);
  };

  const handleNameInput = () => {
    setModalOpen(true);
  };
  // copy to clipboard
  function copyToClipboard(text) {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text);
    } else {
      var input = document.createElement("textarea");
      input.setAttribute("value", text);
      document.body.appendChild(input);
      input.select();
      var result = document.execCommand("copy");
      document.body.removeChild(input);
      return result;
    }
  }

  const handleSaveAsWord = () => {
    const doc = new Document();
    doc.addSection({
      properties: {},
      children: [new Paragraph({ children: [new TextRun(response)] })],
    });
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${project.name}.docx`);
    });
  };

  const handleSaveAsPDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 400]);
    page.drawText(response, {
      x: 50,
      y: 350,
      size: 30,
      color: rgb(0, 0, 0),
    });
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    saveAs(blob, `${project.name}.pdf`);
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await fetch(buildApiEndpoint(`/project/${projectId}`));

        setIsLoading(false);

        if (response.status === 200) {
          const responseData = await response.json();
          setProject(responseData);
          setResponse(responseData.response);
          setEditorState(convertToHtml(responseData.response));
          setNewResponse(responseData.response);
          setName(responseData.name);
          setEmailbody(responseData.response);
          console.log(responseData);
          if (responseData.type == "blank") {
            setHtml(true);
          }

          // console.log(convertToHtml(responseData.response));
        } else {
          console.error("Failed to fetch project");
        }
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };

    fetchProject();
  }, [projectId]);

  // Create an EditorState with the formatted text
  const handleAddMember = async () => {
    setIsLoading(true);
    if (
      !email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrorMessage("Please enter a valid email address");
      setIsLoading(false);
    } else if (!email) {
      setErrorMessage("Please fill all fields");
      setIsLoading(false);
    } else {
      const response = await fetch(
        buildApiEndpoint(`/${projectId}/addMember`),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
          }),
          credentials: "include",
        }
      );

      setIsLoading(false);

      if (response.ok) {
        setErrorMessage("Collaborator added successfully");
        setIsLoading(false);
      } else {
        const responseData = await response.json();
        setIsLoading(false);
        setErrorMessage(responseData.message);
      }
    }
  };

  const handleModal = () => {
    setShowModal(!showModal);
    setErrorMessage("");
  };
  const handleModal2 = () => {
    setShowModal2(!showModal2);
    setErrorMessage("");
  };
  const handleSave = async (e) => {
    setIsLoading(true);

    if (!name) {
      setErrorMessage("Please input document title");
      setIsLoading(false);
    } else if (!response) {
      setErrorMessage("Can't save empty document");
      setIsLoading(false);
    } else {
      try {
        setIsLoading(true);
        const responsed = await fetch(
          buildApiEndpoint(`/update/${projectId}`, true),
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name,
              response: response,
            }),
          }
        );

        setIsLoading(false);

        if (responsed.ok) {
          const responseData = await responsed.json();
          setErrorMessage("Project saved successfully");
          console.log(responseData);
          navigate("/home/projects");
          setIsLoading(false);
        } else {
          const responseData = await responsed.json();
          console.error("Response Data:", responseData);
          setErrorMessage(responseData.message);
          setIsLoading(false);
        }
      } catch (error) {
        setErrorMessage("An error occurred while saving the project.");
        setIsLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="my-8 grid w-full place-items-center overflow-x-auto">
        {message && (
          <Alert message={message} type="success" extra="sticky top-10 z-50" />
        )}
        {project ? (
          <>
            <Card extra={"p-5 space-y-3 h-fit w-full overflow-x-hidden"}>
              <h1 className="text-center text-2xl font-bold capitalize text-navy-900 dark:text-white">
                {project.name ? project.name : "Untitled project"}
              </h1>
              <hr />
              <AIOutput
                onEditorStateChange={onEditorStateChange}
                editorState={editorState}
                isBusinessPlan={false}
                blank={true}
                Emailbody={Emailbody}
              />
              <div className="sm:space-between !mt-4 flex flex-col sm:flex-col sm:space-y-4 md:flex-col md:space-y-4 lg:flex-row">
                <div>
                  <div className="flex w-full flex-row items-center justify-center space-x-5 md:flex-row">
                    <button
                      className=" mt-4 flex max-h-10 w-[200px] items-center justify-center rounded-lg bg-blue-600 py-4 leading-none text-white sm:mt-0"
                      onClick={handleModal}
                    >
                      Add Collaborator
                    </button>
                    <button
                      className=" mt-4 w-fit items-center justify-center rounded-lg bg-green-600 px-4 py-2 text-white sm:mt-0 "
                      onClick={handleNameInput}
                    >
                      Save
                    </button>
                    <button
                      className=" mt-4 inline-flex w-fit rounded-lg bg-red-400 px-4 py-2 text-white sm:mt-0"
                      onClick={handleModal2}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          </>
        ) : null}
        {isLoading && <Loader isLoading={isLoading} />}
      </div>
      {showModal && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-white bg-opacity-70 outline-none focus:outline-none">
            <div className="relative mx-auto my-6 w-auto max-w-3xl">
              {/*content*/}
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="border-slate-200 flex items-start justify-between rounded-t border-b border-solid p-5">
                  <h3 className="text-3xl font-semibold text-navy-900">
                    Add collaborator's email
                  </h3>
                  <button
                    className="text-black float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none opacity-5 outline-none focus:outline-none"
                    onClick={handleModal}
                  >
                    <span className="text-black block h-6 w-6 bg-transparent text-2xl opacity-5 outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                {errorMessage && (
                  <p className="text-center font-medium text-blue-600">
                    {errorMessage}
                  </p>
                )}
                <div className="relative flex-auto px-6">
                  <label className="text-navy-900">Email</label>
                  <input
                    type="text"
                    className="border-slate-300 my-4 w-full rounded-lg border-2 bg-white px-2.5 py-3 text-navy-900"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrorMessage("");
                    }}
                    placeholder="example@gmail.com"
                  />
                </div>
                {/*footer*/}
                <div className="border-slate-200 flex items-center justify-end rounded-b border-t border-solid p-6">
                  <button
                    className="background-transparent mb-1 mr-1 px-6 py-2 text-sm font-bold uppercase text-red-500 outline-none transition-all duration-150 ease-linear focus:outline-none"
                    type="button"
                    onClick={handleModal}
                  >
                    Close
                  </button>
                  <button
                    className="mb-1 mr-1 rounded bg-blue-500 px-6 py-3 text-sm font-bold uppercase text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg focus:outline-none active:bg-blue-600"
                    type="button"
                    onClick={handleAddMember}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            {isLoading && (
              <div className="absolute left-0 top-0 flex h-screen w-full items-center justify-center">
                <ScaleLoader
                  loading={isLoading}
                  size={70}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  color="green"
                />
              </div>
            )}
          </div>
          <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
        </>
      )}
      {showModal2 && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-white bg-opacity-70 outline-none focus:outline-none">
            <div className="relative mx-auto my-6 w-auto max-w-3xl">
              {/*content*/}
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="border-slate-200 flex items-start justify-between rounded-t border-b border-solid p-5">
                  <h3 className="text-3xl font-semibold text-navy-900">
                    Delete Project
                  </h3>
                  <button
                    className="text-black float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none opacity-5 outline-none focus:outline-none"
                    onClick={handleModal2}
                  >
                    <span className="text-black block h-6 w-6 bg-transparent text-2xl opacity-5 outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <p className="p-3 text-base font-semibold text-red-700">
                  Are you sure you want to delete this project?
                </p>
                {/*footer*/}
                <div className="border-slate-200 flex items-center justify-end rounded-b border-t border-solid p-6">
                  <button
                    className="background-transparent mb-1 mr-1 px-6 py-2 text-sm font-bold uppercase text-red-500 outline-none transition-all duration-150 ease-linear focus:outline-none"
                    type="button"
                    onClick={handleModal2}
                  >
                    Close
                  </button>
                  <button
                    className="mb-1 mr-1 rounded bg-blue-500 px-6 py-3 text-sm font-bold uppercase text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg focus:outline-none active:bg-blue-600"
                    type="button"
                    onClick={handleDelete}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
            {isLoading && (
              <div className="absolute left-0 top-0 flex h-screen w-full items-center justify-center">
                <ScaleLoader
                  loading={isLoading}
                  size={70}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  color="green"
                />
              </div>
            )}
          </div>
          <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
        </>
      )}

      {/* <Card extra={"p-5 space-y-3 h-fit w-full"}>
        <CKEditor
          editor={ClassicEditor}
          data="<p>Initial content goes here</p>"
          onReady={(editor) => {
            // You can do something when the editor is ready, if needed
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            console.log({ event, editor, data });
          }}
        />


      </Card> */}

      {modalOpen && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-white bg-opacity-70 outline-none focus:outline-none">
            <div className="relative mx-auto my-6 w-auto max-w-3xl">
              {/*content*/}
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                {errorMessage && (
                  <p
                    className={`text-center text-base font-medium ${
                      errorMessage === "Project saved successfully"
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {errorMessage}
                  </p>
                )}
                {/*header*/}
                <div className="border-slate-200 flex items-start justify-between rounded-t border-b border-solid p-5">
                  <h3 className="text-3xl font-semibold text-navy-900">
                    Save Project
                  </h3>
                  <button className="text-black float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none opacity-5 outline-none focus:outline-none">
                    <span className="text-black block h-6 w-6 bg-transparent text-2xl opacity-5 outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <p className="font-medum p-4 text-center text-lg text-navy-900">
                  What should your project be saved with?
                </p>
                <input
                  type="text"
                  className=" mb-3 ml-5 h-[48px] w-full max-w-xs rounded-lg border-[1px] border-gray-300 bg-white pl-[15px] font-semibold text-navy-900 placeholder:font-medium"
                  onChange={(e) => {
                    setName(e.target.value);
                    setErrorMessage("");
                  }}
                  value={name}
                />
                <div className="border-slate-200 flex items-center justify-end rounded-b border-t border-solid p-6">
                  <button
                    className="background-transparent mb-1 mr-1 px-6 py-2 text-sm font-bold uppercase text-red-500 outline-none transition-all duration-150 ease-linear focus:outline-none"
                    type="button"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    className="mb-1 mr-1 rounded bg-green-500 px-6 py-3 text-sm font-bold uppercase text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg focus:outline-none active:bg-green-600"
                    type="button"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            {isLoading && (
              <div className="absolute left-0 top-0 flex h-screen w-full items-center justify-center">
                <ScaleLoader
                  loading={isLoading}
                  size={70}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  color="green"
                />
              </div>
            )}
          </div>
          <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
        </>
      )}
    </React.Fragment>
  );
};

export default ProjectDetails;
