import React, { useEffect, useState } from "react";
import AIOutput from "components/AIOutput";
import { buildApiEndpoint } from "Utils";
import { useNavigate } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Blank = () => {
  const [editorState, setEditorState] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [response, setResponse] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    setResponse(newEditorState);
  };

  useEffect(() => {
    console.log(name);
  }, [name]);

  const handleNameInput = () => {
    setModalOpen(true);
  };

  const handleSave = async (e) => {
    setIsLoading(true);

    if (!name) {
      toast.error("Please input document title", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setIsLoading(false)
    } else if (!response) {
      toast.error("Can't save empty document", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
       setIsLoading(false)
    } else {
      try {
        setIsLoading(true);
        const responsed = await fetch(buildApiEndpoint("/save", true), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            response,
          }),
        });

        setIsLoading(false);

        if (responsed.ok) {
          const responseData = await responsed.json();
          toast.success("Project saved successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          console.log(responseData);
          navigate("/home/projects");
        } else {
          const responseData = await responsed.json();
          console.error("Response Data:", responseData);
          toast.error(`${responseData.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("An error occured while saving project", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  if (errorMessage) {
    toast.error(`${errorMessage}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  return (
    <React.Fragment>
      <AIOutput
        extra={"md:h-[300px]"}
        onEditorStateChange={onEditorStateChange}
        editorState={editorState}
        blank={true}
      />
      <button
        className="float-right mt-7 rounded-lg border-none bg-green-600 px-6 py-3 font-semibold text-white shadow-lg transition duration-300 ease-in-out hover:scale-110 active:scale-95"
        onClick={handleNameInput}
      >
        Save
      </button>
      {modalOpen && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-white bg-opacity-70 outline-none focus:outline-none">
            <div className="relative mx-auto my-6 w-auto max-w-3xl">
              {/*content*/}
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                {errorMessage && (
                  <p
                    className={`text-center text-base font-medium ${
                      errorMessage === "Project saved successfully"
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    {errorMessage}
                  </p>
                )}
                {/*header*/}
                <div className="border-slate-200 flex items-start justify-between rounded-t border-b border-solid p-5">
                  <h3 className="text-3xl font-semibold text-navy-900">
                    Save Project
                  </h3>
                  <button className="text-black float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none opacity-5 outline-none focus:outline-none">
                    <span className="text-black block h-6 w-6 bg-transparent text-2xl opacity-5 outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <p className="font-medum p-4 text-center text-lg text-navy-900">
                  What should your project be saved with?
                </p>
                <input
                  type="text"
                  className=" mb-3 ml-5 h-[48px] w-full max-w-xs rounded-lg border-[1px] border-gray-300 bg-white pl-[15px] font-semibold text-navy-900 placeholder:font-medium"
                  onChange={(e) => {
                    setName(e.target.value);
                    setErrorMessage("");
                  }}
                />
                <div className="border-slate-200 flex items-center justify-end rounded-b border-t border-solid p-6">
                  <button
                    className="background-transparent mb-1 mr-1 px-6 py-2 text-sm font-bold uppercase text-red-500 outline-none transition-all duration-150 ease-linear focus:outline-none"
                    type="button"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    className="mb-1 mr-1 rounded bg-green-500 px-6 py-3 text-sm font-bold uppercase text-white shadow outline-none transition-all duration-150 ease-linear hover:shadow-lg focus:outline-none active:bg-green-600"
                    type="button"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            {isLoading && (
              <div className="absolute left-0 top-0 flex h-screen w-full items-center justify-center">
                <ScaleLoader
                  loading={isLoading}
                  size={70}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  color="green"
                />
              </div>
            )}
          </div>
          <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
        </>
      )}
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </React.Fragment>
  );
};

export default Blank;
