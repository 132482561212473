

export default function Alert({ message, type, extra}) {

    let typeClassName

    switch (type) {
        case "error":
        case "danger":
            typeClassName = "text-red-500";
            break;

        case "success":
            typeClassName = "text-green-500";
            break;
    }

    return (
        <div className={`mt-10 mb-6 sm:w-full sm:max-w-sm ${extra}`}>
        <p className={`rounded-lg border-[1px] p-2 text-center text-base font-semibold ${typeClassName}`}>
            {message}
        </p>
    </div>
    )
}