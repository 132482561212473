
export default function () {
    return (
        <div className="w-full h-screen flex flex-col justify-center items-center">
            <h1 className="text-4xl font-bold text-navy-700 dark:text-white">404 Not found</h1>
            <a
                className="mt-6 rounded-xl bg-brand-500 p-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
               href="/" 
            >
                Back Home
            </a>
        </div>
    )
}