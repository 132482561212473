import { useAuth } from "authContext";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { buildApiEndpoint, getCookie } from "Utils";

const Pricing = () => {
  const { login, user } = useAuth();
  const storedUser = getCookie("user") // localStorage.getItem("user");
  const navigate = useNavigate();

  useEffect(() => {
    if (storedUser && !user) {
      const user = JSON.parse(storedUser);
      login(user);
    }
  }, [storedUser, login, user]);

  if (!user) {
    navigate("/auth");
  }
  const handleCheckout = async (priceId) => {
    try {
      const response = await fetch(buildApiEndpoint("/subscription"),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials : "include"
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const response2 = await response.json();

      if (response2.subscription !== "free") {
        navigate("/admin");
      } else {
        const session = await fetch(buildApiEndpoint("/checkout"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ priceId }),
          credentials : "include"
        });

        if (!session.ok) {
          throw new Error(`HTTP error! Status: ${session.status}`);
        }

        const sessionData = await session.json();

        if (sessionData) {
          window.open(sessionData.url, "_self");
        }
      }
    } catch (error) {
      console.error("Checkout error:", error);
      // Handle errors here, such as displaying an error message to the user
    }
  };

  return (
    <React.Fragment>
      <section class="h-full bg-white dark:bg-navy-800">
        <div class="container mx-auto h-full px-6 py-8">
          <div class="sm:flex sm:items-center sm:justify-between">
            <div>
              <h2 class="text-3xl font-bold text-gray-800 dark:text-gray-100">
                Simple, transparent pricing
              </h2>
              <p class="mt-4 text-gray-500 dark:text-gray-400">
                No Contracts. No surorise fees.
              </p>
            </div>

            <div class="mt-6 overflow-hidden rounded-lg border p-0.5 dark:border-gray-700">
              <div class="flex sm:-mx-0.5">
                <button class=" w-full rounded-lg bg-blue-500 px-3 py-1 text-white focus:outline-none sm:mx-0.5">
                  Monthly
                </button>
              </div>
            </div>
          </div>

          <div class="-mx-6 mt-16 grid h-full gap-6 sm:grid-cols-2 sm:gap-8 lg:grid-cols-3 xl:grid-cols-4">
            <div class="h-full transform rounded-lg px-6 py-4 transition-colors duration-200 hover:bg-gray-200 dark:hover:bg-gray-700">
              <p class="text-lg font-medium text-gray-800 dark:text-gray-100">
                Free
              </p>
              <h4 class="mt-2 text-4xl font-semibold text-gray-800 dark:text-gray-100">
                $0{" "}
                <span class="text-base font-normal text-gray-600 dark:text-gray-400">
                  / Month
                </span>
              </h4>
              <p class="mt-4 text-gray-500 dark:text-gray-300">
                Free trial for 14 days. Enjoy all features of COPYMAX for 3 days
                when you create your account. free.
              </p>

              <div class="mt-8 space-y-8">
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="mx-4 text-gray-700 dark:text-gray-300">
                    Incredible AI Model
                  </span>
                </div>

                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="mx-4 text-gray-700 dark:text-gray-300">
                    Unlimited Artifacts, Collateral and Documents
                  </span>
                </div>
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="mx-4 text-gray-700 dark:text-gray-300">
                    1000 AI words/month
                  </span>
                </div>
              </div>

              {/* <button class="mt-10 w-full transform rounded-md bg-blue-500 px-4 py-2 font-medium capitalize tracking-wide text-white transition-colors duration-200 hover:bg-blue-600 focus:bg-blue-600 focus:outline-none">
                Choose plan
              </button> */}
            </div>

            <div class="h-full transform rounded-lg px-6 py-4 transition-colors duration-200 hover:bg-gray-200 dark:hover:bg-gray-700">
              <p class="text-lg font-medium text-gray-800 dark:text-gray-100">
                Lite
              </p>
              <h4 class="mt-2 text-4xl font-semibold text-gray-800 dark:text-gray-100">
                $9.99{" "}
                <span class="text-base font-normal text-gray-600 dark:text-gray-400">
                  / Month
                </span>
              </h4>
              <p class="mt-4 text-gray-500 dark:text-gray-300">
                For individuals or small businesses looking for essential
                features at an affordable price
              </p>

              <div class="mt-8 space-y-8">
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="mx-4 text-gray-700 dark:text-gray-300">
                    Incredible AI Model
                  </span>
                </div>

                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="mx-4 text-gray-700 dark:text-gray-300">
                    Unlimited Artifacts, Collateral and Documents
                  </span>
                </div>

                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="mx-4 text-gray-700 dark:text-gray-300">
                    10,000 AI words/ Month
                  </span>
                </div>
              </div>

              <button
                class="mt-10 w-full transform rounded-md bg-blue-500 px-4 py-2 font-medium capitalize tracking-wide text-white transition-colors duration-200 hover:bg-blue-600 focus:bg-blue-600 focus:outline-none"
                onClick={() => {
                  handleCheckout("price_1NuGFWGPnfheVAhxplns2dpS");
                }}
              >
                Choose plan
              </button>
            </div>

            <div class="h-full transform rounded-lg bg-gray-900 px-6 py-4 transition-colors duration-200 dark:bg-gray-900">
              <p class="text-lg font-medium text-gray-100">PRO</p>
              <h4 class="mt-2 text-4xl font-semibold text-gray-100">
                $19.99{" "}
                <span class="text-base font-normal text-gray-400">/ Month</span>
              </h4>
              <p class="mt-4 text-gray-300">
                Growing Businesses, Freelancers and Teams that need extra
                capabilities and functionalities.
              </p>

              <div class="mt-8 space-y-8">
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="mx-4 text-gray-300">Incredible AI Model</span>
                </div>

                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="mx-4 text-gray-300">
                    Unlimited Artifacts, Collateral and Documents
                  </span>
                </div>

                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="mx-4 text-gray-300">50,000 AI words/month</span>
                </div>
              </div>

              <button
                class="mt-10 w-full transform rounded-md bg-blue-500 px-4 py-2 font-medium capitalize tracking-wide text-white transition-colors duration-200 hover:bg-blue-600 focus:bg-blue-600 focus:outline-none"
                onClick={() => {
                  handleCheckout("price_1NuG2EGPnfheVAhxm1I8SBbD");
                }}
              >
                Choose plan
              </button>
            </div>

            <div class="h-full transform rounded-lg px-6 py-4 transition-colors duration-200 hover:bg-gray-200 dark:hover:bg-gray-700">
              <p class="text-lg font-medium text-gray-800 dark:text-gray-100">
                Elite
              </p>
              <h4 class="mt-2 text-4xl font-semibold text-gray-800 dark:text-gray-100">
                $29.99{" "}
                <span class="text-base font-normal text-gray-600 dark:text-gray-400">
                  / Month
                </span>
              </h4>
              <p class="mt-4 text-gray-500 dark:text-gray-300">
                Ultimate choice for power users and enterprises seeking top-tier
                performance
              </p>

              <div class="mt-8 space-y-8">
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="mx-4 text-gray-700 dark:text-gray-300">
                    Incredible AI Model
                  </span>
                </div>

                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="mx-4 text-gray-700 dark:text-gray-300">
                    Unlimited Artifacts, Collateral and Documents
                  </span>
                </div>

                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-blue-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span class="mx-4 text-gray-700 dark:text-gray-300">
                    Unlimited AI words/month
                  </span>
                </div>
              </div>

              <button
                class="mt-10 w-full transform rounded-md bg-blue-500 px-4 py-2 font-medium capitalize tracking-wide text-white transition-colors duration-200 hover:bg-blue-600 focus:bg-blue-600 focus:outline-none"
                onClick={() => {
                  handleCheckout("price_1NuFnmGPnfheVAhxbdURrTjp");
                }}
              >
                Choose plan
              </button>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Pricing;
