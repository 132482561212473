import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { AuthProvider } from "authContext";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import ProjectDetails from "layouts/projectdetails";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import NoPage from "views/NoPage";
import MasterLayout from "layouts/MasterLayout";
const stripePromise = loadStripe(`${process.env.PUBLIC_KEY}`);

const App = () => {
  return (
    <AuthProvider>
      <Elements stripe={stripePromise}>
        <Routes>
          <Route path="auth/*" element={<AuthLayout />} />
          <Route path="admin/*" element={<AdminLayout />} />
          <Route
            path="home/*"
            element={<MasterLayout pathGroup="home" index="dashboard" />}
          />
          <Route
            path="generator/*"
            element={<MasterLayout pathGroup="generator" />}
          />
          <Route
            path="pricing/*"
            element={<MasterLayout pathGroup="pricing" />}
          />
          <Route
            path="projects/*"
            element={<MasterLayout pathGroup="projects" />}
          />
          <Route path="/projects/:projectId/*" element={<ProjectDetails />} />
          <Route path="/" element={<Navigate to="/home/dashboard" replace />} />
          <Route
            path="/login"
            element={<Navigate to="/auth/sign-in" replace />}
          />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </Elements>
    </AuthProvider>
  );
};

export default App;
