import AIOutput from "components/AIOutput";
import Card from "components/card";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useAuth } from "authContext";
import { useNavigate } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
} from "react-share";
import { EmailIcon } from "react-share";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { getCookie, buildApiEndpoint, convertToHtml } from "Utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Insta = () => {
  const [number, setNumber] = useState("");
  const [language, setLanguage] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [contentDescription, setContentDescription] = useState("");
  const [mood, setMood] = useState("");
  const [hashtags, setHashtags] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [Emailbody, setEmailbody] = useState("");
  const [editorState, setEditorState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const { user, login } = useAuth();

  const navigate = useNavigate();
  const storedUser = getCookie("user"); // localStorage.getItem("user");

  useEffect(() => {
    if (storedUser && !user) {
      const user = JSON.parse(storedUser);
      login(user);
    }
  }, [storedUser, login, user]);

  const generateInstaCaption = async (e) => {
    e.preventDefault();
    if (!user) {
      navigate("/auth");
    } else if (
      !language ||
      !name ||
      !targetAudience ||
      !contentDescription ||
      !hashtags ||
      !mood ||
      !number
    ) {
      toast.error("Please fill all fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      try {
        setIsLoading(true);
        const response = await fetch(buildApiEndpoint("/insta", true), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            language,
            targetAudience,
            contentDescription,
            mood,
            hashtags,
            name,
            number,
          }),
        });

        setIsLoading(false);
        if (response.ok) {
          const responseData = await response.json();
          // Set the plain text directly in the editor

          setEditorState(convertToHtml(responseData));
          setEmailbody(responseData);
        } else {
          const responseData = await response.json();
          console.error("Response Data:", responseData);
          toast.error(`${responseData.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (error) {
        console.error("Login error:", error);
      }
    }
  };

  if (errorMessage) {
    toast.error(`${errorMessage}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  return (
    <React.Fragment>
      <div className="mt-8 grid h-fit w-full gap-4 md:grid-cols-2">
        <Card extra={"px-4 py-5 space-y-3"}>
          <h1 className="text-2xl font-extrabold text-navy-700 dark:text-white">
            Your Input
          </h1>
          <p className="mb-4 text-lg font-medium text-gray-600">
            What is your post about?
          </p>
          <textarea
            className=" rounded-lg border-[1px] border-gray-600 bg-gray-200 p-2 font-semibold text-navy-700 dark:text-navy-700"
            rows={12}
            onChange={(e) => {
              setContentDescription(e.target.value);
              setErrorMessage("");
            }}
          ></textarea>
          <div className="">
            <p className=" mt-[20px] text-base font-semibold text-navy-900 dark:text-white">
              Name of project
            </p>
            <input
              type="text"
              placeholder=""
              className=" h-[48px] w-full max-w-xs rounded-lg border-[1px] border-gray-300 bg-white pl-[15px] font-semibold text-navy-900 placeholder:font-medium"
              onChange={(e) => {
                setName(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>
          <div className="">
            <p className=" mt-[10px] text-base font-semibold text-navy-900 dark:text-white">
              Target Audience
            </p>
            <input
              type="text"
              placeholder=""
              className=" h-[48px] w-full max-w-xs rounded-lg border-[1px] border-gray-300 bg-white pl-[15px] font-semibold text-navy-900 placeholder:font-medium"
              onChange={(e) => {
                setTargetAudience(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>
          <div className="">
            <p className=" mt-[10px] text-base font-semibold text-navy-900 dark:text-white">
              Hashtags
            </p>
            <input
              type="text"
              placeholder=""
              className=" h-[48px] w-full max-w-xs rounded-lg border-[1px] border-gray-300 bg-white pl-[15px] font-semibold text-navy-900 placeholder:font-medium"
              onChange={(e) => {
                setHashtags(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>

          <div className="lg:grid lg:grid-cols-2 lg:gap-x-4">
            <select
              className="select select-bordered mt-[12px] w-full max-w-xs bg-white font-bold text-navy-900"
              onChange={(e) => {
                setLanguage(e.target.value);
                setErrorMessage("");
              }}
            >
              <option disabled selected>
                Preferred Language
              </option>
              <option value="Afrikaans">Afrikaans</option>
              <option value="Albanian">Albanian</option>
              <option value="Arabic">Arabic</option>
              <option value="Armenian">Armenian</option>
              <option value="Basque">Basque</option>
              <option value="Bengali">Bengali</option>
              <option value="Bulgarian">Bulgarian</option>
              <option value="Catalan">Catalan</option>
              <option value="Cambodian">Cambodian</option>
              <option value="Chinese (Mandarin)">Chinese (Mandarin)</option>
              <option value="Croatian">Croatian</option>
              <option value="Czech">Czech</option>
              <option value="Danish">Danish</option>
              <option value="Dutch">Dutch</option>
              <option value="English">English</option>
              <option value="Estonian">Estonian</option>
              <option value="Fiji">Fiji</option>
              <option value="Finnish">Finnish</option>
              <option value="French">French</option>
              <option value="Georgian">Georgian</option>
              <option value="German">German</option>
              <option value="Greek">Greek</option>
              <option value="Gujarati">Gujarati</option>
              <option value="Hebrew">Hebrew</option>
              <option value="Hindi">Hindi</option>
              <option value="Hungarian">Hungarian</option>
              <option value="Icelandic">Icelandic</option>
              <option value="Indonesian">Indonesian</option>
              <option value="Irish">Irish</option>
              <option value="Italian">Italian</option>
              <option value="Japanese">Japanese</option>
              <option value="Javanese">Javanese</option>
              <option value="Korean">Korean</option>
              <option value="Latin">Latin</option>
              <option value="Latvian">Latvian</option>
              <option value="Lithuanian">Lithuanian</option>
              <option value="Macedonian">Macedonian</option>
              <option value="Malay">Malay</option>
              <option value="Malayalam">Malayalam</option>
              <option value="Maltese">Maltese</option>
              <option value="Maori">Maori</option>
              <option value="Marathi">Marathi</option>
              <option value="Mongolian">Mongolian</option>
              <option value="Nepali">Nepali</option>
              <option value="Norwegian">Norwegian</option>
              <option value="Persian">Persian</option>
              <option value="Polish">Polish</option>
              <option value="Portuguese">Portuguese</option>
              <option value="Punjabi">Punjabi</option>
              <option value="Quechua">Quechua</option>
              <option value="Romanian">Romanian</option>
              <option value="Russian">Russian</option>
              <option value="Samoan">Samoan</option>
              <option value="Serbian">Serbian</option>
              <option value="Slovak">Slovak</option>
              <option value="Slovenian">Slovenian</option>
              <option value="Spanish">Spanish</option>
              <option value="Swahili">Swahili</option>
              <option value="Swedish ">Swedish </option>
              <option value="Tamil">Tamil</option>
              <option value="Tatar">Tatar</option>
              <option value="Telugu">Telugu</option>
              <option value="Thai">Thai</option>
              <option value="Tibetan">Tibetan</option>
              <option value="Tonga">Tonga</option>
              <option value="Turkish">Turkish</option>
              <option value="Ukrainian">Ukrainian</option>
              <option value="Urdu">Urdu</option>
              <option value="Uzbek">Uzbek</option>
              <option value="Vietnamese">Vietnamese</option>
              <option value="Welsh">Welsh</option>
              <option value="Xhosa">Xhosa</option>
            </select>
            <select
              className="select select-bordered mt-[12px] w-full max-w-xs bg-white font-bold text-navy-900"
              onChange={(e) => {
                setMood(e.target.value);
                setErrorMessage("");
              }}
            >
              <option disabled selected>
                Mood
              </option>
              <option>Professional</option>
              <option>Playful</option>
              <option>Angry</option>
              <option>Sad</option>
              <option>Happy</option>
              <option>Neutral</option>
            </select>
            <select
              className="select select-bordered mt-[12px] w-full max-w-xs bg-white font-bold text-navy-900"
              onChange={(e) => {
                setNumber(e.target.value);
                setErrorMessage("");
              }}
            >
              <option disabled selected>
                Number of captions
              </option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>

          <button
            className=" linear rounded-[20px] bg-brand-700 px-8 py-3 text-base font-medium text-white shadow-md shadow-brand-300 transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
            onClick={generateInstaCaption}
          >
            Generate
          </button>
        </Card>
        <AIOutput
          Emailbody={Emailbody}
          onEditorStateChange={onEditorStateChange}
          editorState={editorState}
          isLoading={isLoading}
          errorMessage={errorMessage}
          isBusinessPlan={true}
        />
      </div>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </React.Fragment>
  );
};

export default Insta;
