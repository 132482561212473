import { buildApiEndpoint } from "Utils";
import React, { useEffect, useState } from "react";
import { useAuth } from "authContext";
import { Link, useNavigate } from "react-router-dom";
import { getCookie } from "Utils";
import Card from "components/card";
import { ScaleLoader } from "react-spinners";

const Shared = () => {
  const [projectIds, setProjectIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const storedUser = getCookie("user");
  const [sharedProjects, setsharedProjects] = useState([]);

  useEffect(() => {
    if (storedUser && !user) {
      const user = JSON.parse(storedUser);
      login(user);
    }
  }, [storedUser, login, user]);

  if (!user) {
    navigate("/auth");
  }

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // Replace 'API_ENDPOINT' with the actual API endpoint to fetch user projects
        const response = await fetch(buildApiEndpoint("/current-user"), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          const sharedProjects = data.projects;
          setProjectIds(sharedProjects);
          setUserId(data._id);
          setIsLoading(false);

          // Update the projects state with fetched data
        } else {
          console.error("Failed to fetch user projects");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching user projects:", error);
      }
    };

    const getProjects = async () => {
      try {
        if (!user) {
          console.error("User not available");
          return;
        }

        const projectData = await Promise.all(
          projectIds.map(async (projectId) => {
            try {
              const response = await fetch(
                buildApiEndpoint(`/user/${userId}/project/${projectId}`),
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  credentials: "include",
                }
              );

              if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
              }

              const project = await response.json();
              return {
                name: project.name,
                content: project.response,
                id: project._id,
              };
            } catch (error) {
              console.error(`Error for Project ID ${projectId}: ${error}`);
              return null; // Return null for failed requests
            }
          })
        );

        // Process the projectData array
        setsharedProjects(projectData);
      } catch (error) {
        console.error(error);
      }
    };

    if (user) {
      fetchUser(); // Fetch projects when the user is available
      getProjects();
    }
  }, [userId]);

  return (
    <React.Fragment>
      <div className="">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
          {isLoading ? (
            <div className=" mt-5 flex h-full w-full items-center justify-center">
              <ScaleLoader
                loading={isLoading}
                size={70}
                aria-label="Loading Spinner"
                data-testid="loader"
                color="green"
              />
            </div>
          ) : projectIds.length === 0 ? (
            <p className="text-align mt-4 font-medium text-navy-900 dark:text-white">
              No project was shared with you
            </p>
          ) : (
            sharedProjects.map((project) => {
              const projectTitle = project.name
                ? project.name
                : "Untitled project";

              return (
                <Link to={`/projects/${project.id}`}>
                  <Card
                    extra={
                      "w-full p-4 h-full mt-[20px] justify-between space-y-8"
                    }
                    key={project.id}
                  >
                    <header className="relative flex items-center justify-between">
                      <div className="text-xl font-bold text-navy-700 dark:text-white">
                        {projectTitle}
                      </div>
                    </header>
                  </Card>
                </Link>
              );
            })
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Shared;
