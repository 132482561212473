import InputField from "components/fields/InputField";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

import { buildApiEndpoint } from "Utils";
import Loader from "components/Loader";
import Alert from "components/Alert";
import Card from "components/card";

export default function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [attemptSuccess, setAttemptSuccess] = useState(false);

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!email || !name) {
      setErrorMessage("Please fill all fields");
    } else if (
      !email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrorMessage("Please enter a valid email address");
    } else {
      setIsLoading(true);
      const lowercaseEmail = email.toLowerCase();
      try {
        const response = await fetch(buildApiEndpoint("/register"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: lowercaseEmail, name, referralCode }),
        });

        setIsLoading(false);

        if (response.ok) {
          setAttemptSuccess(true);

          // localStorage.setItem("signUp", true);

          // navigate("/auth/sign-in");
        } else {
          const responseData = await response.text();
          // console.error("Response Data:", responseData);
          setErrorMessage(responseData);
        }
      } catch (error) {
        setIsLoading(false);
        setErrorMessage("Something went wrong, please try again later");
        console.error("Login error:", error);
      }
    }
  };

  return attemptSuccess ? (
    <div className="justify-centre mt-6 flex h-full w-full items-center">
      <Card extra={"items-center flex-col w-full h-full p-[16px] bg-cover"}>
        {/* Background and profile */}
        <div className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover">
          <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white dark:!border-navy-700">
            <AiOutlineMail className="h-60 w-60" />
          </div>
        </div>

        {/* Name and position */}
        <div className="mt-16 flex flex-col items-center">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            Email sent!
          </h4>
          <h5 className="mt-4 text-center text-base font-normal text-gray-600">
            A link has been sent to your email. verify your email to continue
          </h5>
          <small className="mt-4 text-gray-600">
            Check your spam folder if you can't find the mail.
          </small>
        </div>
        <a
          href="/"
          className="mt-6 rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Go back
        </a>
      </Card>
    </div>
  ) : (
    <div className="mt-16 flex h-fit w-full items-center justify-center px-2 md:mx-0 md:px-0  lg:items-center ">
      {/* Sign in section */}
      <div className=" h-fit w-full max-w-full flex-col items-center md:max-w-[420px] md:pl-4 lg:pl-0">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign Up
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Fill your details to create your account for free!
        </p>

        {errorMessage && <Alert message={errorMessage} type="danger" />}

        {/* Name */}
        <InputField
          variant="auth"
          extra="mb-3 text-navy-900"
          label="Name*"
          placeholder="John"
          id="name"
          type="text"
          onChange={(e) => {
            setName(e.target.value);
            setErrorMessage("");
          }}
        />
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3 text-navy-900"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          type="text"
          onChange={(e) => {
            setEmail(e.target.value);
            setErrorMessage("");
          }}
        />
        <InputField
          variant="auth"
          extra="mb-3 text-navy-900"
          label="Referral Code (Optional)"
          placeholder="ABC736"
          id="code"
          type="text"
          onChange={(e) => {
            setReferralCode(e.target.value);
            setErrorMessage("");
          }}
        />
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={handleSignup}
        >
          Continue
        </button>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Have an account?
          </span>
          <Link
            to="/auth"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Log in instead
          </Link>
        </div>
      </div>
      {isLoading && <Loader isLoading={isLoading} />}
    </div>
  );
}
