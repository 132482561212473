import AIOutput from "components/AIOutput";
import Card from "components/card";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useAuth } from "authContext";
import { useNavigate } from "react-router-dom";
import LanguageOption from "components/LanguagesOption";

import "react-quill/dist/quill.snow.css";

import { getCookie, buildApiEndpoint, convertToHtml } from "Utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Insta = () => {
  const [objective, setObjective] = useState("");
  const [targetAudience, settargetAudience] = useState("");
  const [platform, setPlatform] = useState("");
  const [Emailbody, setEmailbody] = useState("");
  const [name, setName] = useState("");
  const [language, setLanguage] = useState("");
  const [productOrService, setProductOrService] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [editorState, setEditorState] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const { user, login } = useAuth();

  const navigate = useNavigate();
  const storedUser = getCookie("user"); // localStorage.getItem("user");

  useEffect(() => {
    if (storedUser && !user) {
      const user = JSON.parse(storedUser);
      login(user);
    }
  }, [storedUser, login, user]);

  const generateInstaCaption = async (e) => {
    e.preventDefault();
    if (!user) {
      navigate("/auth");
    } else if (
      !objective ||
      !targetAudience ||
      !platform ||
      !name ||
      !language ||
      !productOrService
    ) {
      toast.error("Please fill all fields", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      try {
        setIsLoading(true);
        const response = await fetch(buildApiEndpoint("/ads", true), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            targetAudience,
            objective,
            platform,
            name,
            language,
            productOrService,
          }),
        });

        setIsLoading(false);
        if (response.ok) {
          const responseData = await response.json();
          // Set the plain text directly in the editor
          setEditorState(convertToHtml(responseData));
          setEmailbody(responseData);
        } else {
          const responseData = await response.json();
          console.error("Response Data:", responseData);
          toast.error(`${responseData.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (error) {
        console.error("Login error:", error);
      }
    }
  };

  if (errorMessage) {
    toast.error(`${errorMessage}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  return (
    <React.Fragment>
      <div className="mt-8 grid h-fit w-full gap-4 md:grid-cols-2">
        <Card extra={"px-4 py-5 space-y-3"}>
          <h1 className="text-2xl font-extrabold text-navy-700 dark:text-white">
            Your Input
          </h1>
          <p className=" text-lg font-medium text-gray-600">Ads objective</p>
          <textarea
            className=" mt-[20px] rounded-lg border-[1px] border-gray-600 bg-gray-200 p-2 font-semibold text-navy-700 dark:text-navy-700"
            rows={12}
            onChange={(e) => {
              setObjective(e.target.value);
              setErrorMessage("");
            }}
          ></textarea>

          <div>
            <p className="  text-base font-semibold text-navy-900 dark:text-white">
              Audience
            </p>
            <input
              type="text"
              placeholder="Adults of age 25-27"
              className="h-[48px] w-full max-w-xs rounded-lg border-[1px] border-gray-300 bg-white pl-[15px] font-semibold text-navy-900 placeholder:font-medium"
              onChange={(e) => {
                settargetAudience(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>
          <div>
            <p className="  text-base font-semibold text-navy-900 dark:text-white">
              Product or Service
            </p>
            <input
              type="text"
              placeholder="Shoes"
              className="h-[48px] w-full max-w-xs rounded-lg border-[1px] border-gray-300 bg-white pl-[15px] font-semibold text-navy-900 placeholder:font-medium"
              onChange={(e) => {
                setProductOrService(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>

          <div>
            <p className="  text-base font-semibold text-navy-900 dark:text-white">
              Name of Project
            </p>
            <input
              type="text"
              placeholder="Ads for ...."
              className="h-[48px] w-full max-w-xs rounded-lg border-[1px] border-gray-300 bg-white pl-[15px] font-semibold text-navy-900 placeholder:font-medium"
              onChange={(e) => {
                setName(e.target.value);
                setErrorMessage("");
              }}
            />
          </div>

          <div className="lg:grid lg:grid-cols-2 lg:gap-4">
            <select
              className="select select-bordered  w-full max-w-xs bg-white font-bold text-navy-900"
              onChange={(e) => {
                setPlatform(e.target.value);
                setErrorMessage("");
              }}
            >
              <option disabled selected>
                Platform
              </option>
              <option>Facebook</option>
              <option>Twitter</option>
              <option>Instagram</option>
              <option>Whatsapp</option>
              <option>Linkedin</option>
            </select>

            <select
              className="select select-bordered mt-[12px] w-full max-w-xs bg-white font-bold text-navy-900 lg:mt-0"
              onChange={(e) => {
                setLanguage(e.target.value);
                setErrorMessage("");
              }}
            >
              <LanguageOption />
            </select>
          </div>
          <button
            className=" linear rounded-[20px] bg-brand-700 px-8 py-3 text-base font-medium text-white shadow-md shadow-brand-300 transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
            onClick={generateInstaCaption}
          >
            Generate
          </button>
        </Card>
        <AIOutput
          Emailbody={Emailbody}
          onEditorStateChange={onEditorStateChange}
          editorState={editorState}
          isLoading={isLoading}
          errorMessage={errorMessage}
          isBusinessPlan={true}
        />
      </div>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>
    </React.Fragment>
  );
};

export default Insta;
