import Card from "components/card";
import { FiClock, FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "authContext";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useNavigate } from "react-router-dom";
import CardMenu2 from "../../../components/card/CardMenu2";
import { getCookie, buildApiEndpoint } from "Utils";

const Marketplace = () => {
  const { login, user } = useAuth();
  const storedUser = getCookie("user"); // localStorage.getItem("user");
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const navigate = useNavigate(); // State to store fetched projects

  useEffect(() => {
    if (storedUser && !user) {
      const user = JSON.parse(storedUser);
      login(user);
    }
  }, [storedUser, login, user]);

  if (!user) {
    navigate("/auth");
  }

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        // Replace 'API_ENDPOINT' with the actual API endpoint to fetch user projects
        const response = await fetch(buildApiEndpoint("/user-projects"), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          setProjects(data);
          setIsLoading(false);
          // Update the projects state with fetched data
        } else {
          console.error("Failed to fetch user projects");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching user projects:", error);
      }
    };

    if (user) {
      fetchProjects();
    }
  }, [user]);

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // You can customize the date format here
  };

  return (
    <div>
      <div className="z-5 relative mt-[25px] flex items-center justify-between rounded-[20px] bg-white bg-clip-border px-3 py-10 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none">
        <p className="text-xl font-bold text-navy-700 dark:text-white">
          All Contents ({projects.length})
        </p>

        <Link to="/">
          <button className="linear rounded-[20px] bg-brand-700 px-8 py-3 text-base font-medium text-white shadow-md shadow-brand-300 transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
            New Content
          </button>
        </Link>
      </div>
      <div className="mt-[20px] flex h-full items-center rounded-lg border-2 border-gray-500 bg-lightPrimary py-3 text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
        <p className=" pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search..."
          class="bottom-1 block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
        />
      </div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        {isLoading ? (
          <div className=" mt-5 flex h-full w-full items-center justify-center">
            <ScaleLoader
              loading={isLoading}
              size={70}
              aria-label="Loading Spinner"
              data-testid="loader"
              color="green"
            />
          </div>
        ) : projects.length === 0 ? (
          <p className="text-align mt-4 font-medium text-navy-900 dark:text-white">
            No project saved
          </p>
        ) : (
          projects.map((project) => {
            const formattedDateTime = formatDateTime(project.updatedAt);
            const projectTitle = project.name
              ? project.name
              : "Untitled project";

            return (
              <Link to={`/projects/${project._id}`}>
                <Card
                  extra={
                    "w-full p-4 h-full mt-[20px] justify-between space-y-8"
                  }
                  key={project.id}
                >
                  <header className="relative flex items-center justify-between">
                    <div className="text-xl font-bold text-navy-700 dark:text-white">
                      {projectTitle}
                    </div>
                  </header>
                  <p className="flex items-center">
                    <FiClock className="mr-2" /> Created at {formattedDateTime}
                  </p>
                </Card>
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Marketplace;
