import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { Link } from "react-router-dom";
import { useAuth } from "authContext";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ScaleLoader from "react-spinners/ScaleLoader";
import Loader from "components/Loader";
import Alert from "components/Alert";

import { buildApiEndpoint, setCookie } from "Utils";

export default function SignIn() {
  const { login } = useAuth();
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [signUp, setsignUp] = useState(false);
  const [resetP, setResetP] = useState(false);
  const navigate = useNavigate();
  const [is2FA, setIs2FA] = useState(false);
  const [otp, setOTP] = useState(0);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setErrorMessage("Please fill all fields");
    } else if (
      !email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrorMessage("Please enter a valid email address");
    } else if (password.length < 6) {
      setErrorMessage("Password should not be less than 6 characters");
    } else {
      setErrorMessage("");
      setIsLoading(true);
      const lowercaseEmail = email.toLowerCase();
      try {
        const response = await fetch(buildApiEndpoint("/login"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({ email: lowercaseEmail, password }),
          credentials: "include",
        });

        setIsLoading(false);

        if (response.ok) {
          const userData = await response.json();
          console.log(userData);
          if (
            userData.message ==
            "Two-Factor Authentication is enabled for this user"
          ) {
            setIs2FA(true);
          } else finishLogin(userData);
        } else {
          const responseData = await response.text();
          // console.error("Response Data:", responseData);
          setErrorMessage(responseData);
        }
      } catch (error) {
        setIsLoading(false);
        setErrorMessage("Something went wrong, try again later");
        console.error("Login error:", error);
      }
    }
  };

  async function verify2FA() {
    setErrorMessage("");
    setIsLoading(true);
    const lowercaseEmail = email.toLowerCase();
    try {
      const response = await fetch(buildApiEndpoint("/verify2fa"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ email: lowercaseEmail, code: otp }),
        credentials: "include",
      });

      setIsLoading(false);

      if (response.ok) {
        const userData = await response.json();

        if (userData.picture) {
          finishLogin(userData);
        } else setErrorMessage("Check your code and try again");
      } else {
        const responseData = await response.text();
        // console.error("Response Data:", responseData);
        setErrorMessage(responseData);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage("Something went wrong, try again later");
      console.error("Login error:", error);
    }
  }

  function finishLogin(userData) {
    login(userData);
    // localStorage.setItem("user", JSON.stringify(userData));
    navigate("/home/dashboard");
  }

  useEffect(() => {
    //Runs only on the first render
    if (localStorage.getItem("signUp")) {
      setsignUp(true);

      localStorage.removeItem("signUp");
    }

    if (localStorage.getItem("resetP")) {
      setResetP(true);

      localStorage.removeItem("resetP");
    }
  }, []);

  return is2FA ? (
    // Enter 2FA code
    <div className="mx-auto mt-8 flex h-fit w-full items-center justify-center px-2 lg:items-center">
      {/* Sign in section */}
      <div className=" h-fit w-full max-w-full flex-col items-center md:max-w-[420px] md:pl-4 lg:pl-0">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          2-Factor Authentication
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter the code that was sent to your email
        </p>

        {errorMessage && <Alert message={errorMessage} type="danger" />}

        <InputField
          variant="auth"
          extra="mb-3 text-navy-900"
          placeholder="123456"
          type="number"
          onChange={(e) => {
            setErrorMessage("");
            setOTP(e.target.value);
          }}
        />
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={verify2FA}
        >
          Verify
        </button>
      </div>
      {isLoading && (
        <div className="absolute left-0 top-0 flex h-[120vh] w-full items-center justify-center overflow-hidden bg-[#000] bg-opacity-50">
          <ScaleLoader
            loading={isLoading}
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
            color="green"
          />
        </div>
      )}
    </div>
  ) : (
    <div className="mx-auto mt-8 flex h-fit w-full items-center justify-center px-2 lg:items-center">
      {/* Sign in section */}
      <div className=" h-fit w-full max-w-full flex-col items-center md:max-w-[420px] md:pl-4 lg:pl-0">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>

        {signUp && (
          <Alert message="Sign up successful, please login" type="success" />
        )}

        {resetP && (
          <Alert
            message="Password reset successfully, please login"
            type="success"
          />
        )}

        {errorMessage && <Alert message={errorMessage} type="danger" />}

        <InputField
          variant="auth"
          extra="mb-3 text-navy-900"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          type="text"
          onChange={(e) => {
            setEmail(e.target.value);
            setErrorMessage("");
          }}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3 text-navy-900"
          label="Password*"
          placeholder="Min. 6 characters"
          id="password"
          type="password"
          onChange={(e) => {
            setPassword(e.target.value);
            setErrorMessage("");
          }}
        />
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <Link
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            to="/auth/forgot-password"
          >
            Forgot Password?
          </Link>
        </div>
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={handleLogin}
        >
          Sign In
        </button>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <Link
            to="/auth/signup"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </Link>
        </div>
      </div>
      {isLoading && <Loader isLoading={isLoading} />}
    </div>
  );
}
